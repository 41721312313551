.brand-hub-carousel-section {
  background-color: $cx-white;
  padding: 4.6875rem 4.875rem 0;
  position: relative;
  // padding: 4.6875rem 4.875rem  !important;

  @include breakpoints(largeUp) {
    &.with-collage {
      min-height: 40rem;
      padding: 4.6875rem 4.875rem 3rem;
      margin-top: 7rem;

      .section-content {
        top: 7rem !important;

        &.right {
          right: -4.375rem !important;
        }

        &.left {
          left: -4.375rem !important;
        }
      }

      .section-collage {
        left: 4.875rem !important;
      }
    }

    &.without-collage {
      min-height: 30rem;
      // margin-top: 6.25rem;
      padding: 12rem 4.875rem;
      height: 40rem !important;
    }
  }

  @include breakpoints(smallAndMedium) {
    padding: 0;
  }

  .carousel-section-row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0 auto;
    max-width: 1280px;
    // height: 40rem;

    .section-carousel {
      z-index: 1;
      width: 48%;
      top: 32px;

      &.show {
        display: block;
      }

      @include breakpoints(smallAndMedium) {
        width: 100vw;
        display: block;
      }

      @include breakpoints(largeUp) {
        opacity: 0;
        transition: opacity .5s ease-in, transform .5s ease-out;
        // min-width: 30rem;
      }

      &.active {
        opacity: 1;
        // height: 100px;
      }

      .slick-slide-wrapper {
        position: relative;
      }

      .icon-arrow-backward {
        opacity: .9;
        color: $cx-blue-link;
        background-color: $cx-white;
        width: 2.5rem;
        height: 2.5rem;
        padding: .75rem .9375rem;
        position: absolute;
        bottom: .3125rem;
        right: 2.5rem;
        z-index: 1;
        cursor: pointer;
      }

      .icon-arrow-forward {
        opacity: .9;
        color: $cx-blue-link;
        background-color: $cx-white;
        width: 2.5rem;
        height: 2.5rem;
        padding: .75rem .9375rem;
        position: absolute;
        bottom: .3125rem;
        right: 0;
        z-index: 1;
        cursor: pointer;
      }

      .btn-carousel-video-play {
        background-color: rgba(255, 255, 255, 0);
        border-color: rgba(255, 255, 255, 0);
        display: block;
        position: absolute;
        bottom: .5rem;
        right: .5rem;
        cursor: pointer;
        height: 3.5rem;
        width: 3.5rem;
        padding: 0;
        z-index: 1;

        @include breakpoints(smallAndMedium) {
          height: 5rem;
          width: 5rem;
          bottom: 50%;
          right: 50%;
          transform: translate(50%, 50%);
        }

        .icon-play {
          color: $cx-white;
          display: block;
          font-size: 2rem;

          @include breakpoints(smallAndMedium) {
            font-size: 3.5rem;
          }

          &:before {
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
          }
        }
      }

      &.right {
        @include breakpoints(largeUp) {
          position: absolute;
          right: 0;
        }
      }

      &.left {
        @include breakpoints(largeUp) {
          position: absolute;
          left: 80px;

          .icon-arrow-backward {
            left: 0;
            right: unset;
          }

          .icon-arrow-forward {
            left: 2.5rem;
            right: unset;
          }
        }
      }

      .slick-slider {
        @include breakpoints(smallAndMedium) {
          width: 100vw;
        }

        .slick-slide {
          .slide-img{
            width: 100%;

            @include breakpoints(smallAndMedium) {
              width: 100%;
              height: calc(100vw*0.5);
              object-fit: cover;
              // display: block;
            }
          }
          .slide-video {
            // height: 10%;
            width: 100%;
            // width: calc( 0.58 * ( 100vw - 2 * 4.875rem ));
            // height: calc( 0.58 * ( 100vw - 2 * 4.875rem ) * 0.5625 );
            // object-fit: cover;
            // max-width: 736px;
            // max-height: 414px;
          }

          .slide-video {
            // cursor: pointer;
          }
        }
      }
    }

    .section-content {
      width: 42%;
      padding: 2.1875rem 0;
      background-color: rgba(255, 255, 255, 0);
      z-index: 2;

      @include breakpoints(largeUp) {
        min-width: 22rem;
      }

      .btn-read-more {
        opacity: 0;
        padding: 1px 1rem 0;
        height: 3rem;
        min-width: 9.5rem;
        border: 1px solid $cx-blue-link;
        border-radius: 2px;
        color: $cx-blue-link;
        background-color: rgba(255, 255, 255, 0);
        cursor: pointer;
        transform: translateY(1rem);
        transition: all 1s ease-out .3s;
        @include small-font-size;
        @include cx-font-regular;

        &.active {
          opacity: 1;
          transform: translateY(0rem);
        }

        &:hover,
        &:active {
          text-decoration: underline;
        }
      }

      &.right {
        background-color: rgba(255, 255, 255, 0);

        @include breakpoints(largeUp) {
          position: absolute;
          top: -20px !important;
          right: 0;
        }
      }

      &.left {
        background-color: rgba(255, 255, 255, 0);

        @include breakpoints(largeUp) {
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      @include breakpoints(smallAndMedium) {
        padding: 5rem 2.5rem 5rem;
        width: 100%;
        position: relative;
        top: unset;
        right: unset;
        left: unset;
      }

      @include breakpoints(smallOnly) {
        padding: 1.5rem 2.5rem 5rem;
        width: 100%;
        position: relative;
        top: unset;
        right: unset;
        left: unset;
      }

      .title {
        color: $cx-grey-dark;
        line-height: 2.375rem;
        // letter-spacing: 4px;
        margin-bottom: 1.125rem;
        font-family: 'CathaySans_Rg';

        @include breakpoints(smallAndMedium) {
          text-align: center;
        }
      }

      .description {
        color: $cx-grey-dark;
        margin-bottom: 1.125rem;
        line-height: 1.25rem;
        @include cx-font-light;

        a {
          color: $cx-blue-link;
        }
      }
    }

    .section-collage {
      display: none;
      flex-wrap: wrap;
      position: relative;
      width: 58%;
      top: 3rem;

      .collage-first-col {
        width: 58%;
        padding: .5rem;
      }

      .collage-second-col {
        width: 42%;
        padding: .5rem;
        display:flex;
        flex-direction: column;
        justify-content: center;
      }

      .collage-item-1 {
        width: 70%;
        margin-left: 30%;
        position: relative;
        top: -2rem;
        right: 0;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out;
      }

      .collage-item-2 {
        width: 100%;
        position: relative;
        top: -1rem;
        right: 0;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out .3s;
      }

      .collage-item-3 {
        width: 100%;
        position: relative;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out .15s;
      }

      &.active {
        .collage-item-1,
        .collage-item-2,
        .collage-item-3 {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .collage-img,
      .collage-video {
        width: 100%;
      }

      &.show {
        @include breakpoints(largeUp) {
          display: flex;
          transition: transform 1s ease-out;
        }
      }

      &.right {
        right: 0;
      }

      &.left {
        left: 0;
      }

      .collage-video {
        cursor: pointer;
      }

      .btn-collage-video-play {
        background-color: rgba(255, 255, 255, 0);
        border-color: rgba(255, 255, 255, 0);
        display: block;
        position: absolute;
        top: calc(50% - 1.75rem);
        left: calc(50% - 1.75rem);
        cursor: pointer;
        height: 3.5rem;
        width: 3.5rem;
        padding: 0;
        z-index: 1;

        .icon-play {
          color: $cx-white;
          display: block;
          font-size: 2rem;

          &:before {
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
          }
        }

        @include breakpoints(largeUp) {
          top: initial;
          left: initial;
          right: .5rem;
          bottom: .5rem;
        }
      }
    }
  }

  .dash-line {
    background-color: #C1B49A;
    flex: 1 1 auto;
    margin: 0;
    opacity: .5;
    height: 0;
    width: 1px;
    position: absolute;
    top: -11rem;
    right: 23rem;
    transition-property: height;
    transition-duration: 1.25s;
    transition-delay: .3s;
    transition-timing-function: cubic-bezier(0.28, 0.11, 0.32, 1);

    &.active {
      height: 8.125rem;
    }

    @include breakpoints(smallAndMedium) {
      display: none;
    }
  }
}
