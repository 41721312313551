@-webkit-keyframes cross-fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes cross-fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// To reset bootstrap, in order to use our animation
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}