@import "common/index.scss";
@import "basic-section.scss";
@import "section-with-carousel.scss";
@import "image-banner.scss";
@import "responsive-navbar.scss";
@import "responsive-footer.scss";
@import "hackathon-role-section.scss";
@import "sponsor-section.scss";
@import "brushwing.scss";
@import "vapour.scss";
@import "subscribe-section.scss";
@import "how-to-join-section.scss";
@import "eday-highlight-section.scss";
@import "seattle-highlight-section.scss";
@import "awards-section.scss";
@import "video-pop-up.scss";
@import "statement-section.scss";
@import "keydates.scss";
@import "statistics-section.scss";
@import "journey.scss";
@import "header-section.scss";
@import "highlights-section.scss";
@import "recap-section.scss";
@import "faq-section.scss";
@import "power-section.scss";
@import "apply-now.scss";
@import "video-section.scss";
@import "event-details.scss";
@import "prizes-and-awards.scss";
@import 'banner-announcement.scss';
@import 'history-banner-section.scss';
@import 'history-highlights-section.scss';
@import 'history-legacy-section.scss';
@import 'about-cathay-hackathon.scss';
@import "language-selector-banner.scss";

.h1, h1 {
    font-size: 34px;
    line-height: 48px;
    font-family: 'GT Walsheim';
}

.h2, h2 {
    font-size: 28px;
    line-height: 40px;
    font-family: 'GT Walsheim';
}

.h3, h3 {
    font-size: 24px;
    line-height: 36px;
    font-family: 'GT Walsheim';
}

.h4, h4 {
    font-size: 22px;
    line-height: 32px;
    font-family: 'GT Walsheim';
}

.p, p {
    font-size: 16px;
    line-height: 24px;
    font-family: 'CathaySans_Rg';
}

.brand-hub-page {
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}
