//
// Color collections
// --------------------------------------------------

// Primary / CTA (Call-To-Action)
$cathay-dark-cyan: #367878;
$cathay-dark-cyan-darker: #163230;
$cathay-dark-cyan-lighter: #d7e5e4;
$cathay-dark-green: #2c4036;
$cathay-dark-green-darker: #002527;
$cathay-dark-green-lighter: #6fa088;

// Text / Netural
$cathay-dark-grey: #2d2d2d;
$cathay-dark-grayish-blue: #66686a;
$cathay-grayish-blue: #bcbec0;
$cathay-light-gray: #f5f5f5;
$cathay-white: #ffffff;
$cathay-gray: #808285;
$cathay-black: #000000;

// Secondar / Gold Series
$cathay-gold-light-grey: #f9f9f9;
$cathay-gold-light-orange: #f3f1ef;
$cathay-gold-light-yellow: #f7f6f1;
$cathay-gold: #877a5c;

// Utility
$cathay-alert: #fed700;
$cathay-error: #d11f00;
$cathay-unavailable: #c2262e;
$cathay-reminder-and-disable: #e6e7e8;
$cathay-tips: #fcf6ea;
$cathay-success: #367878;

// Lifestyle Pillar
$cathay-pillar-flight: #F1F4F1;

//
// Deprecated
// --------------------------------------------------

// Primary
$cx-green: #387e79;
$cx-green-sub: #e5efef;
$cx-green-sub-dark: #d3dfde;
$cx-white: #ffffff;
$cx-white-grey: #f9faf9;
$cx-black: #000000;
$cx-red: #c2262e;
$cx-blue-grey: #7da5b7;
$cx-blue-grey-sub: #edf3f5;
$cx-blue-grey-sub-dark: #dbe2e4;
$cx-blue-navy: #466792;
$cx-blue-navy-sub: #e5eaf0;
$cx-blue-navy-sub-dark: #d3dadf;
$cx-red-maroon: #a26673;
$cx-red-maroon-sub: #f2e9ec;
$cx-red-maroon-sub-dark: #e0d9da;
$cxd-red: #a51e36;
$cxd-deep-red: #8b2332;
$cxd-light-red: #b05d56;
$cx-yellow: #f7f6f1;
$cx-cyan: #d7e5e4;
// Secondary
$cx-green-secondary: #367d79;
$cx-green-warm: #498169;
$cx-gold: #8e8573;
$cx-champagne: #dcd3bc;
$cx-clay: #fcf6ea;
$cx-blue-link: #0f748f;
$cx-dark-green: #2c4036;
$cx-dark-grey: #2D2D2D;
// Interface colours
$cx-error: #d11f00;
$cx-alert: #fed700;
$cx-error-bg: #f6e8ea;

// Classes
$cx-economy: #005c63;
$cx-premium-economy: #4e86a0;
$cx-pre-economy: #487c94; // $cx-premium-economy color cannot meet accessibility requirement
$cx-business: #002e6c;
$cx-first: #832c40;

// 5 shades of grey - from darkest to lightest
$cx-grey-dark: #2d2d2d;
$cx-grey-medium: #767676;
$cx-grey-baidu: #9d9e9d;
$cx-grey-warm: #c6c2c1;
$cx-grey-cool: #d7d7d7;
$cx-grey-light: #ebedec;
$cx-grey-silver: #e5e5e5;
$cx-grey-gradient: #f5f5f5;
$cx-grey-platinum: #f9f9f9;

// KA color codes collection
$ka-deep-red: #8b182e;
$ka-red-medium: #e91a22;
$ka-dragon-red: #a51e36;
$ka-gold: #a49463;
$ka-blue-link: #284c89;
$ka-blue-link-neo: #284c8a;
$ka-black: #000000;
$ka-grey-dark: #4c4c4c;
$ka-grey-medium: #767676;
$ka-grey-warm: #c6c2c1;
$ka-grey-cool: #d7d7d7;
$ka-grey-light: #ebedec;
$ka-white: #ffffff;
$ka-clay: #fcf6ea;
$ka-error: #d11f00;
$ka-red-logo: #a41f35;
$ka-light-red: #b05d56;

// MPO branded colors
$mpo-solid-green: #005c63;
$mpo-solid-silver: #696a6a;
$mpo-solid-gold: #886521;
$mpo-solid-diamond: #2e2a25;
$aml-solid-grey: #c6c2c1;

//MPO go (triangle) colors
$mpo-solid-green-go: #ffffff;
$mpo-solid-silver-go: #81877c;
$mpo-solid-gold-go: #a28541;
$mpo-solid-diamond-go: #a7b4b5;
$aml-solid-grey-go: #c6c2c1;

//MPO tier colors
$mpo-solid-green-tier: #005c63;
$mpo-solid-silver-tier: #9a9a9b;
$mpo-solid-gold-tier: #a27e2d;
$mpo-solid-diamond-tier: #2e2a25;
$aml-solid-grey-tier: #c6c2c1;

//Scrolling Table
$cx-scrolling-table-scrollbar-color: #005c63;
$cx-scrolling-table-background-odd: #ecf2f0;
$cx-scrolling-table-background-even: #ffffff;
$cx-scrolling-table-background-header: #005c63;

$ka-scrolling-table-scrollbar-color: #8b2332;
$ka-scrolling-table-background-odd: #f6f4ef;
$ka-scrolling-table-background-even: #ffffff;
$ka-scrolling-table-background-header: #8b2332;

// Instant search promotion

$background-economy: #e6efef;
$background-premium-economy: #edf2f4;
$background-business: #e6eaf0;
$tooltip-default-border-color: #202f42;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Small screen / phone
$screen-sm: 0px !default;
$screen-sm-min: $screen-sm !default;

// Medium screen / tablet
$screen-md: 720px !default;
$screen-md-min: $screen-md !default;

// Large screen / desktop
$screen-lg: 1024px !default;
$screen-lg-min: $screen-lg !default;

// Medium Large screen / desktop
$screen-md-lg: 1170px !default;
$screen-md-lg-min: $screen-md-lg !default;

// Extra large screen / desktop
$screen-extra-lg: 1440px !default;
$screen-extra-lg-min: $screen-extra-lg !default;

$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
