.awards-section {
  background-color: #f9f9f9;
  position: relative;
  padding: 0 calc((100vw - 960px) / 2);
  // padding: 4.6875rem 4.875rem  !important;

  @include breakpoints(largeUp) {
    &.with-collage {
      min-height: 40rem;
      // padding: 4.875rem 4.875rem;
      padding-top: 4.875rem;
      padding-bottom: 4.875rem;
      margin-top: 7rem;

      .section-content {
        top: 7rem !important;

        &.right {
          right: -4.375rem !important;
        }

        &.left {
          left: -4.375rem !important;
        }
      }

      .section-collage {
        left: 4.875rem !important;
      }
    }

    &.without-collage {
      min-height: 30rem;
      // margin-top: 6.25rem;
      // padding: 4.875rem 4.875rem;
      padding-top: 4.875rem;
      padding-bottom: 4.875rem;
      height: auto;
    }
  }

  @include breakpoints(smallAndMedium) {
    padding: 4.875rem 2.5rem 2.5rem;
  }

  @include breakpoints(smallOnly) {
    padding: 4.875rem 2.5rem 2.5rem;
  }
  .computer-view-gap {
    @include breakpoints(largeUp) {
      padding-right: 10%;
    }
    transform: translateY(0rem);
    transition: transform 1s ease-out, opacity 1s ease-out;

    &.active {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .carousel-section-row-title {
    // display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0 auto;
    // max-width: 1280px;
    height: auto;

    .section-content {
      width: auto;
      margin-bottom: 2.1875rem;
      // padding: 0 2.5rem;
      background-color: rgba(255, 255, 255, 0);
      z-index: 2;
      // position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      // top: 50%;

      @include breakpoints(largeUp) {
        min-width: 22rem;
      }

      // .btn-read-more {
      //   opacity: 0;
      //   padding: 1px 1rem 0;
      //   height: 3rem;
      //   min-width: 9.5rem;
      //   border: 1px solid $cx-blue-link;
      //   border-radius: 2px;
      //   color: $cx-blue-link;
      //   background-color: rgba(255, 255, 255, 0);
      //   cursor: pointer;
      //   transform: translateY(1rem);
      //   transition: all 1s ease-out .3s;
      //   @include small-font-size;
      //   @include cx-font-regular;

      //   &.active {
      //     opacity: 1;
      //     transform: translateY(0rem);
      //   }

      //   &:hover,
      //   &:active {
      //     text-decoration: underline;
      //   }
      // }

      // &.bot {
      //   background-color: rgba(255, 255, 255, 0);

      //   @include breakpoints(largeUp) {
      //     // position: absolute;
      //     // top: 0;
      //     // right: 0;
      //   }
      // }

      &.top {
        background-color: rgba(255, 255, 255, 0);

        @include breakpoints(largeUp) {
          // position: absolute;
          // top: 0;
          // left: 0;
        }
      }

      @include breakpoints(smallAndMedium) {
        // padding: 1.5rem 2.5rem 1.5rem;
        // width: 100%;
        // position: relative;
        // top: unset;
        // right: unset;
        // left: unset;
      }

      .title {
        color: $cx-grey-dark;
        line-height: 2.375rem;
        // letter-spacing: 4px;
        margin-bottom: 64px;
        font-family: "GT Walsheim", "CathaySans_Rg";
        opacity: 0;
        transform: translateY(-10rem);
        transition: transform 1s ease-out, opacity 1s ease-out;

        &.active {
          opacity: 1;
          transform: translateY(0);
        }

        @include breakpoints(smallAndMedium) {
          margin-bottom: 16px;
        }
      }

      .description {
        // transition: all 1s;
        color: $cx-grey-dark;
        margin-bottom: 1.125rem;
        line-height: 1.25rem;
        @include cx-font-regular;
        position: absolute;
        margin-right: 40px;

        a {
          color: $cx-blue-link;
        }

        &.fadeOut {
          opacity: 0;
          // width:0;
          // height:0;
          transition: opacity 1s;
        }

        &.fadeIn {
          opacity: 1;
          // width:100px;
          // height:100px;
          transition: opacity 1s;
        }
      }
    }

    .section-collage {
      display: none;
      flex-wrap: wrap;
      position: relative;
      width: auto;
      top: 3rem;

      .collage-first-col {
        width: 58%;
        padding: 0.5rem;
      }

      .collage-second-col {
        width: 42%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .collage-item-1 {
        width: 70%;
        margin-left: 30%;
        position: relative;
        top: -2rem;
        right: 0;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out;
      }

      .collage-item-2 {
        width: 100%;
        position: relative;
        top: -1rem;
        right: 0;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out 0.3s;
      }

      .collage-item-3 {
        width: 100%;
        position: relative;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out 0.15s;
      }

      &.active {
        .collage-item-1,
        .collage-item-2,
        .collage-item-3 {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .collage-img,
      .collage-video {
        width: 100%;
      }

      &.show {
        @include breakpoints(largeUp) {
          display: flex;
          transition: transform 1s ease-out;
        }
      }

      &.right {
        right: 0;
      }

      &.left {
        left: 0;
      }

      .collage-video {
        cursor: pointer;
      }

      .btn-collage-video-play {
        background-color: rgba(255, 255, 255, 0);
        border-color: rgba(255, 255, 255, 0);
        display: block;
        position: absolute;
        top: calc(50% - 1.75rem);
        left: calc(50% - 1.75rem);
        cursor: pointer;
        height: 3.5rem;
        width: 3.5rem;
        padding: 0;
        z-index: 1;

        .icon-play {
          color: $cx-white;
          display: block;
          font-size: 2rem;

          &:before {
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
          }
        }

        @include breakpoints(largeUp) {
          top: initial;
          left: initial;
          right: 0.5rem;
          bottom: 0.5rem;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .carousel-section-row {
      width: 100% !important;
    }
  }

  .carousel-section-row {
    // display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0 auto;
    // max-width: 1280px;
    height: auto;
    left: -6px;
    width: 100%;

    .section-carousel {
      z-index: 1;
      width: auto;
      justify-content: center;
      align-items: center;
      text-align: center;

      // &.show {
      //   display: block;
      // }

      @include breakpoints(smallAndMedium) {
        // width: 100vw;
        display: block;
      }

      @include breakpoints(largeUp) {
        opacity: 0;
        transition: opacity 0.5s ease-in, transform 0.5s ease-out;
        min-width: 30rem;
        // display: flex;
      }

      &.active {
        opacity: 1;
      }

      .awards {
        margin-bottom: 16px;
        text-align: right;
        font-family: "CathaySans_Rg";
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .control-column-width {
          flex-wrap: nowrap;
          width: 100%;
          // @include breakpoints(smallOnly) {
          //   flex-wrap: wrap;
          // }
          transform: translateY(0rem);
          transition: transform 1s ease-out, opacity 1s ease-out;

          &.active {
            opacity: 1;
            transform: translateY(0);
          }
          @media screen and (min-width:0px) and (max-width: 1023px) {
            flex-wrap: wrap;
          }
        }
        .award-list {
          transform: translateY(-1rem);
          transition: transform 1s ease-out, opacity 1s ease-out;
          &.active {
            opacity: 1;
            transform: translateY(0rem);
          }
        }
        .max-size-for-prize-logo {
          max-width: fit-content;
          max-height: 80px;
        }
        .title {
          color: $cx-grey-dark;
          line-height: 2.375rem;
          // letter-spacing: 4px;
          margin-bottom: 1.125rem;
          font-family: "GT Walsheim", "CathaySans_Rg";
          transform: translateY(-1rem);
          transition: transform 1s ease-out, opacity 1s ease-out;
          &.active {
            opacity: 1;
            transform: translateY(0rem);
          }
          @include breakpoints(largeUp) {
            white-space: nowrap;
          }
          @include breakpoints(mediumUp) {
            margin-bottom: 16px;
            text-align: right;
          }
          @media screen and (min-width:0px) and (max-width: 1023px) {
            margin-bottom: 16px;
            text-align: left;
          }
          // @include breakpoints(smallOnly) {
          //   margin-bottom: 16px;
          //   text-align: left;
          // }
        }
        .upper {
          display: flex;
          align-items: center;
          justify-content: center;

          .upperleft {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          .upperright {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          .uppermiddle {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        }

        .award-container {
          @include breakpoints(smallOnly) {
            display: none;
          }

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;

          .award-row {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            text-align: center;
            width: 100%;

            .award-wrapper {
              margin: 0 40px;
              display: flex;
              justify-content: center;
              align-content: center;
              width: 50%;

              .award-icon {
                margin-bottom: 16px;
                height: 112px;
                width: 112px;
              }
            }

            .award-title-wrapper {
              margin: 0 40px 8px;
              display: flex;
              justify-content: center;
              align-content: center;
              width: 50%;
            }

            .award-word {
              display: flex;
              justify-content: center;
              align-content: center;
              width: auto;
              font-family: "CathaySans_Rg";
            }
          }

          .lowerleft {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          .lowerright {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        }

        .award-container-sml-view {
          @include breakpoints(mediumUp) {
            display: none;
          }

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          padding: 0 0 2rem;

          .award-row {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            text-align: center;
            width: 100%;

            .award-wrapper {
              margin: 0 40px;
              display: flex;
              justify-content: center;
              align-content: center;
              width: 50%;

              .award-icon {
                margin-bottom: 16px;
                height: 112px;
                width: 112px;
              }
            }

            .award-title-wrapper {
              margin: 0 40px 8px;
              display: flex;
              justify-content: center;
              align-content: center;
              width: 50%;
            }

            .award-word {
              display: flex;
              justify-content: center;
              align-content: center;
              width: auto;
            }
          }

          .lowerleft {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          .lowerright {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
        }

        h4 {
          font-family: "CathaySans_Rg";
          color: $cx-grey-dark;
          // letter-spacing: 4px;
        }

        p {
          line-height: normal;
        }

        .award-icon {
          justify-content: center;
          height: 112px;
          width: 112px;
          margin-bottom: 16px;
        }
      }

      .slick-slide-wrapper {
        position: relative;
      }

      .icon-arrow-backward {
        opacity: 0.9;
        color: $cx-blue-link;
        background-color: $cx-white;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.75rem 0.9375rem;
        position: absolute;
        left: 0;
        top: 45%;
        // bottom: .3125rem;
        // right: 2.5rem;
        z-index: 1;
        cursor: pointer;
      }

      .icon-arrow-forward {
        opacity: 0.9;
        color: $cx-blue-link;
        background-color: $cx-white;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.75rem 0.9375rem;
        position: absolute;
        top: 45%;
        // bottom: .3125rem;
        right: 0;
        z-index: 1;
        cursor: pointer;
      }

      .btn-carousel-video-play {
        background-color: rgba(255, 255, 255, 0);
        border-color: rgba(255, 255, 255, 0);
        display: block;
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
        height: 3.5rem;
        width: 3.5rem;
        padding: 0;
        z-index: 1;

        @include breakpoints(smallAndMedium) {
          height: 5rem;
          width: 5rem;
          bottom: 50%;
          right: 50%;
          transform: translate(50%, 50%);
        }

        .icon-play {
          color: $cx-white;
          display: block;
          font-size: 2rem;

          @include breakpoints(smallAndMedium) {
            font-size: 3.5rem;
          }

          &:before {
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
          }
        }
      }

      &.bot {
        @include breakpoints(largeUp) {
          position: relative;
          right: 0;
          top: 64px;
        }
      }

      // &.top {
      //   @include breakpoints(largeUp) {
      //     position: relative;
      //     left: 0;

      //     .icon-arrow-backward {
      //       left: 0;
      //       right: unset;
      //     }

      //     .icon-arrow-forward {
      //       left: 2.5rem;
      //       right: unset;
      //     }
      //   }
      // }

      .slick-slider {
        @include breakpoints(smallAndMedium) {
          width: 100vw;
        }
        // display: flex;
        // align-items: center;
        // display:table-cell;
        // vertical-align: middle;

        .slick-slide {
          .slide-img,
          .slide-video {
            // display: block;
            // height: 10%;
            width: 100%;
            // width: calc( 0.58 * ( 100vw - 2 * 4.875rem ));
            height: calc(0.58 * (100vw - 2 * 4.875rem) * 0.5625);
            object-fit: cover;
            // max-width: 736px;
            max-height: 414px;
          }

          .slide-video {
            // cursor: pointer;
          }
        }
      }
    }

    .section-content {
      width: auto;
      margin-bottom: 2.1875rem;
      padding: 0 2.5rem;
      background-color: rgba(255, 255, 255, 0);
      z-index: 2;
      // position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      // top: 50%;

      @include breakpoints(largeUp) {
        min-width: 22rem;
      }

      // .btn-read-more {
      //   opacity: 0;
      //   padding: 1px 1rem 0;
      //   height: 3rem;
      //   min-width: 9.5rem;
      //   border: 1px solid $cx-blue-link;
      //   border-radius: 2px;
      //   color: $cx-blue-link;
      //   background-color: rgba(255, 255, 255, 0);
      //   cursor: pointer;
      //   transform: translateY(1rem);
      //   transition: all 1s ease-out .3s;
      //   @include small-font-size;
      //   @include cx-font-regular;

      //   &.active {
      //     opacity: 1;
      //     transform: translateY(0rem);
      //   }

      //   &:hover,
      //   &:active {
      //     text-decoration: underline;
      //   }
      // }

      // &.bot {
      //   background-color: rgba(255, 255, 255, 0);

      //   @include breakpoints(largeUp) {
      //     // position: absolute;
      //     // top: 0;
      //     // right: 0;
      //   }
      // }

      &.top {
        background-color: rgba(255, 255, 255, 0);

        @include breakpoints(largeUp) {
          // position: absolute;
          // top: 0;
          // left: 0;
        }
      }

      @include breakpoints(smallAndMedium) {
        // padding: 1.5rem 2.5rem 1.5rem;
        // width: 100%;
        // position: relative;
        // top: unset;
        // right: unset;
        // left: unset;
      }

      .title {
        color: $cx-grey-dark;
        line-height: 2.375rem;
        // letter-spacing: 4px;
        margin-bottom: 64px;
        font-family: "GT Walsheim", "CathaySans_Rg";
        opacity: 0;

        @include breakpoints(smallAndMedium) {
          margin-bottom: 16px;
        }
      }

      .description {
        // transition: all 1s;
        color: $cx-grey-dark;
        margin-bottom: 1.125rem;
        line-height: 1.25rem;
        @include cx-font-regular;
        position: absolute;
        margin-right: 40px;

        a {
          color: $cx-blue-link;
        }

        &.fadeOut {
          opacity: 0;
          // width:0;
          // height:0;
          transition: opacity 1s;
        }

        &.fadeIn {
          opacity: 1;
          // width:100px;
          // height:100px;
          transition: opacity 1s;
        }
      }
    }

    .section-collage {
      display: none;
      flex-wrap: wrap;
      position: relative;
      width: auto;
      top: 3rem;

      .collage-first-col {
        width: 58%;
        padding: 0.5rem;
      }

      .collage-second-col {
        width: 42%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .collage-item-1 {
        width: 70%;
        margin-left: 30%;
        position: relative;
        top: -2rem;
        right: 0;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out;
      }

      .collage-item-2 {
        width: 100%;
        position: relative;
        top: -1rem;
        right: 0;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out 0.3s;
      }

      .collage-item-3 {
        width: 100%;
        position: relative;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out 0.15s;
      }

      &.active {
        .collage-item-1,
        .collage-item-2,
        .collage-item-3 {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .collage-img,
      .collage-video {
        width: 100%;
      }

      &.show {
        @include breakpoints(largeUp) {
          display: flex;
          transition: transform 1s ease-out;
        }
      }

      &.right {
        right: 0;
      }

      &.left {
        left: 0;
      }

      .collage-video {
        cursor: pointer;
      }

      .btn-collage-video-play {
        background-color: rgba(255, 255, 255, 0);
        border-color: rgba(255, 255, 255, 0);
        display: block;
        position: absolute;
        top: calc(50% - 1.75rem);
        left: calc(50% - 1.75rem);
        cursor: pointer;
        height: 3.5rem;
        width: 3.5rem;
        padding: 0;
        z-index: 1;

        .icon-play {
          color: $cx-white;
          display: block;
          font-size: 2rem;

          &:before {
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
          }
        }

        @include breakpoints(largeUp) {
          top: initial;
          left: initial;
          right: 0.5rem;
          bottom: 0.5rem;
        }
      }
    }
  }

  .dash-line {
    background-color: #c1b49a;
    flex: 1 1 auto;
    margin: 0;
    opacity: 0.5;
    height: 0;
    width: 1px;
    position: absolute;
    top: -11rem;
    right: 23rem;
    transition-property: height;
    transition-duration: 1.25s;
    transition-delay: 0.3s;
    transition-timing-function: cubic-bezier(0.28, 0.11, 0.32, 1);

    &.active {
      height: 8.125rem;
    }

    @include breakpoints(smallAndMedium) {
      display: none;
    }
  }
}
