.sponsor-section {
  padding: 16px;
  padding-top: 40px;
  padding-bottom: 48px;
  display: flex;
  font-family: 'cathaySans_Rg';

  &__container {
    width: 1280px;
    margin: auto;
  }

  &__title {
    text-align: center;
    margin-bottom: 32px;
    color: $cathay-dark-grey;
  }

  &__col {
    margin-bottom: 32px;
    text-align: center;
    width: fit-content;
    margin-left:10px;
    margin-right:10px;

    @include breakpoints(smallAndMedium) {
      margin: auto;
      margin-bottom:40px;
    }
  }

  &__row {
    text-align: center;
    justify-content: center;
    @include breakpoints(smallAndMedium) {
      flex-direction: column;
      align-content: center;
    }
  }

  &__quote {
    color: $cathay-dark-grayish-blue;
    justify-content: center;
    text-align: center;
  }

  &__sponsor-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @include breakpoints(smallAndMedium) {
      justify-content: center;
      flex-direction: column;
      gap: 0px
    }
  }

  &__sponsor-logo {
    // height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 40px;

    @include breakpoints(largeUp) {
      flex-direction: column;
      justify-content: center;
      margin-top: 16px;
      width: fit-content;
    }

    & > img {
      height: auto;
      width: auto;
      max-width: 173px;
      max-height: 70px;
    }
  }

  &__built {
    margin: 0 50%;
  }
}
