.hero-banner {
  height: 600px;
  overflow: hidden;
  position: relative;

  // Create gradient filter
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background: linear-gradient(180deg, rgba(76, 76, 76, 0.07) 52.5%, rgba(0, 0, 0, 0.50) 100%, rgba(217, 217, 217, 0.00) 100%);
    @include breakpoints(smallAndMedium) {
      background: linear-gradient(180deg, rgba(44, 44, 44, 0.07) 35.59%, rgba(0, 0, 0, 0.50) 99.95%, rgba(80, 80, 80, 0.00) 99.96%);
    }
  }

  &__img {
    display: block;
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    @media screen and (max-width:720px) {
      display: none;
    }
  }

  &__img_responsive {
    display: block;
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width:721px) {
      display: none;
    }
  }

  .hero-banner__text-wrapper {
    position: absolute;
    bottom: 40px;
    width: 100%;
    font-family: 'CathaySans_Rg';
    color: $cathay-white;
    text-shadow: 0 0 4px rgb(0 0 0 / 80%);
    z-index: 1;
    transform: translateX(-50%);
    left: 50%;
    padding-left: 12px;
    padding-right: 12px;

    .container {
      max-width: 1280px;
      padding-left: 0px;
      padding-right: 0px;
    }

    @include breakpoints(smallAndMedium) {
      .container {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  .hero-banner__down-arrow {
    position: absolute;
    bottom: 6px;
    width: 100%;
    color: $cathay-white;
    z-index: 1;
    display: flex;
    justify-content: center;

    .icon {
      width: 35px;
      height: 35px;

      &:hover{
        cursor: pointer;
      }
    }
  }

  .hero-banner__headline {
    display: block;
    text-align: left;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .hero-banner__title {
    font-family: "GT Walsheim";
    display: block;

    @include breakpoints(smallOnly) {
      font-size: 28px;
    }
  }

  .hero-banner__text {
    display: block;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 16px;
    max-width: 900px;
    @media screen and (max-width:720px) {
      display: none !important; //override framer motion
    }
  }

  .hero-banner__text_responsive {
    display: block;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 16px;
    max-width: 900px;
    @media screen and (min-width:721px) {
      display: none !important; //override framer motion
    }
  }

  .apply-button {
    appearance: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    border-radius: 0;
    padding: 18px 24px;
    min-width: 235px;
    margin-right: 8px;
    margin-bottom: 8px;

    color: $cathay-white;
    border: 1px solid $cathay-white;
    background-color: transparent;
    box-shadow: 0 0 4px rgb(0 0 0 / 40%);
    text-shadow: 0 0 4px rgb(0 0 0 / 80%);

    &:hover {
      text-decoration: underline;
    }
  }

  @keyframes MoveUpDown {
    0% {
      transform: translateY(0);
    }

    0.01%,
    10%,
    20%,
    100% {
      transform: translateY(0);
    }

    5%,
    15% {
      transform: translateY(0.2rem);
    }
  }
}
