.video-pop-up {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  z-index: 10;
  opacity: 0;
  overflow: hidden;

  &.fade {
    transition: opacity 0.15s linear;

    .pop-up-wrapper {
      transition: -webkit-transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
      -webkit-transform: translate(0, -25%);
      transform: translate(0, -25%);
    }
  }

  &.show {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: visible;
    opacity: 1;

    .pop-up-wrapper {
      max-width: 100%;
      width: auto;
      margin: 0 10px;
      transform: translate(0, 0);
    }
  }

  .pop-up-content {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-clip: padding-box;
    border: 0;
    border-radius: .625rem;
    outline: 0;

    .outside-box-close {
      position: absolute;
      top: -2.5rem;
      right: 0;
      width: 2rem;
      height: 2rem;
      opacity: 1;
      color: $cx-white;
      cursor: pointer;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
      background-color: rgba(255, 255, 255, 0);
    }

    .pop-up-body {
      position: relative;
      flex: 1 1 auto;
      padding: 0;

      .embed-video-wrapper {
        background-color: $cx-black;
        position: relative;
        display: block;
        width: 65vw;
        height: 70vh;
        padding: 0;
        overflow: hidden;

        @include breakpoints(smallAndMedium) {
          width: 80vw;
          height: 60vh;
        }

        .embed-video, .popup-video {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
  }
}
