
.history-legacy {
    background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
    color: $cathay-dark-grey;
    padding-top: 40px;
    padding-left: 12px;
    padding-right: 12px;

    &__container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 1280px;
        padding-left: 0px;
        padding-right: 0px;
    }

    &__heading {
        color:#006564;
        font-size:18px;
        line-height:28px;
        font-family: "CathaySans_Rg";
    }

    &__cards-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 25px;
    }

    &__freescroll {
        display: none;
        gap: 20px;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        padding-bottom: 30px;

        &::-webkit-scrollbar {
            display: none;
        }

        @include breakpoints(smallAndMedium) {
            display: flex;
        }
    }

    &__carousel {
        margin-left: -12.5px;
        margin-right: -12.5px;
        padding-bottom: 30px;
        display: flex;

        &__wrapper {
            @include breakpoints(smallAndMedium) {
                display: none;
            }
        }
    }

    &__dot-container {
        margin-left: 12.5px !important;
        margin-right: 12.5px !important;
    }

    &__dot {
        width: 100%;
        background: $cx-grey-light;
        height: 4px;

        &--active {
            background: $cathay-dark-cyan;
        }

        @include breakpoints(smallAndMedium) {
            display: none;
        }
    }

    &__arrow {
        opacity: 0;
        background-color: $cathay-gold-light-grey;
        border-radius: 100%;
        width: 48px;
        height: 48px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.23);
        display: flex;
        justify-content: center;

        &--left {
            transform: translateX(-23px);
        }

        &--right {
            transform: translateX(23px);
        }

        &--active {
            opacity: 1;
            cursor: pointer;
        }

        &__icon {
            position: absolute;
            width: 35px;
            height: 35px;
            justify-self: center;
            align-self: center;
            // hey, I'm just following the figma design.
            // don't shoot the messenger.
            transform: translateX(2px);
        }

        &__icon-left {
            // hey, I'm just following the figma design.
            // don't shoot the messenger.
            transform: rotateY(180deg) translateX(2px);
        }

        @include breakpoints(smallAndMedium) {
            display: none;
        }

        :hover {
            filter: invert(41%) sepia(4%) saturate(4485%) hue-rotate(131deg) brightness(98%) contrast(85%);
        }

    }

    &__button-group {
        display: flex;
        justify-content: space-between;
        position: relative;
        // I know, this is ugly.
        // But before you go and criticize developers for their apparent laziness and unclean code,
        // please try to understand that when the unrealistic demands of MarkComms
        // leads you down a path of madness, you have to do what you have to do.
        // Sincerely, a developer who only wants the best for the Cathay Hackathon.
        transform: translateY(-317px);
    }
}

.history-legacy-card {
    &__wrapper {
        margin-left: 12.5px;
        margin-right: 12.5px;
        color: $cathay-white;
        position: relative;

        @include breakpoints(smallAndMedium) {
            margin-left: 0 !important;
            margin-right: 0 !important;
            min-width: 350px;
        }
    }

    &__img {
        width: 100%;
        height: 486px;
        object-fit: cover;
    }

    &__title {
        position: absolute;
        left: 24px;
        bottom: 20px;
        width: 75%;
        text-decoration: none;
        cursor: pointer;

        &__img {
            // "It's really hard to tell how far outside the box you really are"
            // - OceanGate CEO Stockton Rush, who tragically died in the submarine he built
            padding-left: 10px;
            margin-bottom: 4px;
        }

        &__text {
            font-family: "CathaySans_Rg";
            color: $cathay-white;
        }

        :hover {
            text-decoration: underline;
        }
    }
}
