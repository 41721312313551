.banner-announcement {
  background-color: #f1f4f1;
  color: $cathay-dark-grey;

  &__container {
    max-width: 1280px;
    display: flex;
    justify-content: flex-start;
    padding: 12px;

    @include breakpoints(md) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__icon {
    margin-right: 12px;
  }

  &__text {
    a {
      color: $cathay-dark-cyan;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      color: $cathay-dark-grey;
      font-family: GT Walsheim;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0;

      strong {
        color: $cathay-dark-cyan;
        font-weight: 700;
      }
    }
  }
}
