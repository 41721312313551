.prizes-and-awards {
  background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
  color: $cathay-dark-grey;
  position: relative;
  padding-top: 40px;
  padding-bottom: 64px;
  padding-left: 12px;
  padding-right: 12px;

  display: flex;
  flex-direction: column;
  align-items: center;
  @include breakpoints(smallAndMedium) {
    background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
    padding: 48px 16px 34px;
  }

  .content_box {
    padding-right: 0px;
    max-width: 1280px;
    padding-left: 0px;
  }


  .section_content {
    color: #006564;    
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;    
  }

  .title_content {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    font-family: 'GT Walsheim';
  }

  &__card_container {
    display: flex;
    @include breakpoints(smallAndMedium) {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__icon_col {
      flex: 1;

      &__icon {
        width: 35px;
      }
    }

    &__content_col{
      flex: 10;
      margin-left: 15px;
      // margin-right: 40px;

      &__title{
        font-size: 24px;
        margin-bottom: 10px;
        font-family: 'GT Walsheim';
      }
    }

    li {
      margin-bottom: 10px; 
    }

    li::marker {
      color: #006564;
      content:"•    ";
    }

  }

  .prizes-and-awards-container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 60px;

    @include breakpoints(smallAndMedium) {
      flex-direction: column;
      max-width: 100%;
      // gap: 40px;
    }

    .prizes-and-awards-content {
      text-align: center;
      width: 300px;
      margin: auto;

      .rounded-circle-wrapper {
        padding: 0px;
        justify-content: center;
        align-content: center;
        .img {
          height: 39.38px;
          width: 43.75px;
        }

        .rounded-circle {
          margin-bottom: 40px;
          width: 80px;
          height: 80px;
          @include breakpoints(smallAndMedium) {
            margin-top: 40px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  .apply-now-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__apply-button {
      margin-top: 25px;
      display: inline-block;
      text-align: center;
      appearance: none;
      border: 0;
      outline: 0;
      cursor: pointer;
      border-radius: 0;
      padding: 18px 24px;
      min-width: 235px;
      margin-bottom: 8px;
      transition: all 0.25s ease-in-out;
      font-size: 16px;
      font-family: 'CathaySans_Rg';
  
      color: $cathay-white;
      background-color: $cathay-dark-green;
  
      &:hover {
        color: $cathay-white;
        background-color: $cathay-dark-green-darker;
        text-decoration: underline;
      }
    }
  }

  .basic-section-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1280px;
    justify-content: center;
    // align-items: center;

    .section-title {
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      // letter-spacing: 4px;
      line-height: 2.5rem;
      color: $cx-grey-dark;
      max-width: 70%;
      position: relative;
      font-family: 'GT Walsheim', 'CathaySans_Rg';
      margin: 0 0 4rem;
      @include heading-big-font-size;

      @include breakpoints(smallAndMedium) {
        margin-bottom: 0px;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .title {
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;

        &.active {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .dash-line {
        background-color: #c1b49a;
        flex: 1 1 auto;
        margin: 0;
        opacity: 0.5;
        height: 1px;
        width: 0;
        position: absolute;
        bottom: 2rem;
        transition-property: width;
        transition-duration: 1.25s;
        transition-delay: 1s;
        transition-timing-function: cubic-bezier(0.28, 0.11, 0.32, 1);

        &.active {
          width: 5.625rem;
        }

        @include breakpoints(smallAndMedium) {
          bottom: 0;
        }
      }
    }

    .section-content-smallview {
      padding: 0 32px 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      // flex: 0 0 50%;
      max-width: 80%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-regular;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;

      @include breakpoints(mediumUp) {
        display: none;
      }
      // width: 720px;

      .prizes-and-awards-content {
        // flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 0 16px 32px;

        .rounded-circle {
          margin-bottom: 16px;
        }
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }
    }

    .section-content {
      padding: 0 32px 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      // flex: 0 0 50%;
      max-width: 80%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-regular;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;

      @include breakpoints(smallOnly) {
        display: none;
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }
    }
  }

  .prizes-and-awards-paragraph {
    margin-top: 24px;
    text-align: center;
  }

  .visual-cue {
    background-color: $cx-white;
    color: $cx-blue-link;
    position: absolute;
    cursor: pointer;
    right: calc(50% - 1.25rem);
    top: -1.25rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    @include extra-small-font-size;

    @include breakpoints(smallAndMedium) {
      top: -2.25rem;
    }

    .icon-arrow-down {
      animation: MoveUpDown 3s linear infinite;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}
