.apply-key-dates-timeline {
    background-color: $cx-white;
    position: relative;
    padding: 1rem 1rem;
    @include breakpoints(smallAndMedium) {
      padding: 2.5rem 2.5rem 2.5rem;
    }
    @include breakpoints(small) {
      padding: 2.5rem 2.5rem 2.5rem;
    }
    padding-bottom: 48px;

    .apply-button {
      display: flex;
      width: 216px;
      height: 60px;
      padding: 10px 32px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      line-height: 24px;
      background-color: Transparent;
      background-repeat: no-repeat;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      border: 1px solid $cx-green;
      .myNavLink {
        color: $cx-green;
      }
    }
    .apply-button:hover {
      .myNavLink {
        text-decoration: underline;
      }
    }

    .timeline-section-row-title {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 1280px;
      justify-content: space-evenly;
      width: fit-content;
      .timeline-title {
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        // letter-spacing: 4px;
        line-height: 2.5rem;
        color: $cx-grey-dark;
        max-width: 100%;
        position: relative;
        font-family: "GT Walsheim";
        margin: 0 0 0rem;
        @include heading-big-font-size;
  
        @include breakpoints(smallAndMedium) {
          margin-bottom: 0rem;
          flex: 0 0 100%;
          max-width: 100%;
        }
  
        .time-title {
          opacity: 0;
          transform: translateY(-1rem);
          transition: transform 1s ease-out, opacity 1s ease-out;
          width: 100%;
          margin-bottom: 0px;
  
          &.active {
            opacity: 1;
            transform: translateY(0);
            line-height: 2.5rem;
          }
          @include breakpoints(smallAndMedium) {
            flex: 0 0 100%;
            max-width: 100%;
            line-height: 18px;
          }
        }
      }
      .section-content-smallview {
        padding: 0px;
        line-height: 1.25rem;
        color: #ffffff;
        // flex: 0 0 50%;
        max-width: 80%;
        opacity: 0;
        transform: translateY(1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;
        @include cx-font-light;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        display: flex;
        flex-direction: column;
  
        @include breakpoints(mediumUp) {
          display: none;
        }
        // width: 720px;
  
        .keyEvent {
          // flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          padding: 0 0px 32px;
  
          .time-rounded-circle {
            margin-bottom: 16px;
          }
        }
  
        &.active {
          opacity: 1;
          transform: translateY(0);
        }
  
        @include breakpoints(smallAndMedium) {
          flex: 0 0 100%;
          max-width: 100%;
        }
  
        a {
          color: $cx-blue-link;
        }
  
        html,
        body {
          font-family: arial, sans-serif;
        }
      }
    }
    .timeline-section-row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 1280px;
      justify-content: space-evenly;
      flex-direction: column;
      // align-items: center;
  
      .section-content-smallview {
        padding: 0px;
        line-height: 1.25rem;
        color: #ffffff;
        // flex: 0 0 50%;
        max-width: 90%;
        opacity: 0;
        transform: translateY(1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;
        @include cx-font-light;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        display: flex;
        flex-direction: column;
  
        @include breakpoints(mediumUp) {
          display: none;
        }
        // width: 720px;
  
        .keyEvent {
          // flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          padding: 0 0px 32px;
  
          .time-rounded-circle {
            margin-bottom: 16px;
          }
        }
  
        &.active {
          opacity: 1;
          transform: translateY(0);
        }
  
        @include breakpoints(smallAndMedium) {
          flex: 0 0 100%;
          max-width: 100%;
        }
  
        a {
          color: $cx-blue-link;
        }
  
        html,
        body {
          font-family: arial, sans-serif;
        }
      }
  
      .timeline-content {
        padding: 0;
        line-height: 1.25rem;
        color: #ffffff;
        // flex: 0 0 50%;
        max-width: 100%;
        opacity: 0;
        transform: translateY(1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;
        @include cx-font-light;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        display: flex;
        flex-direction: column;
  
        @include breakpoints(smallOnly) {
          display: none;
        }
  
        &.active {
          opacity: 1;
          transform: translateY(0);
        }
  
        @include breakpoints(smallAndMedium) {
          flex: 0 0 100%;
          max-width: 100%;
        }
  
        a {
          color: $cx-blue-link;
        }
      }
    }
  
    .visual-cue {
      background-color: $cx-white;
      color: $cx-blue-link;
      position: absolute;
  
      right: calc(50% - 1.25rem);
      top: -1.25rem;
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      @include extra-small-font-size;
  
      @include breakpoints(smallAndMedium) {
        top: -2.25rem;
      }
  
      .icon-arrow-down {
        animation: MoveUpDown 3s linear infinite;
      }
  
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  
    $mobileview-centeralign-eventname: 18px;
    .firstcol {
      margin-top: $mobileview-centeralign-eventname;
    }
  
    ul.mobileview-timeline:before {
      content: " ";
      background: #fff;
      display: inline-block;
      position: absolute;
      left: 16%;
      width: 4px;
      height: 87%;
      z-index: 400;
      margin-top: $mobileview-centeralign-eventname;
    }
  
    ul.mobileview-timeline > li {
      //margin: 35px 0;
      padding-left: 0px;
      list-style: none;
      text-align: center;
      margin-top: 35px;
    }
    ul.mobileview-timeline > li:before {
      content: " ";
      background: $cx-green;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 4px solid #fff;
      left: 12%;
      width: 20px;
      height: 20px;
      z-index: 400;
      margin-top: 16px;
    }
    ul.mobileview-timeline-last > li {
      //margin: 35px 0;
      padding-left: 0px;
      list-style: none;
      text-align: center;
      margin-top: 35px;
    }
    ul.mobileview-timeline-last > li:before {
      content: " ";
      background: $cx-green;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 4px solid #fff;
      left: 12%;
      width: 20px;
      height: 20px;
      z-index: 400;
    }
    ul.mobileview-timeline-2 {
      padding: 0px;
    }
    ul.mobileview-timeline-2 > li {
      margin-top: 35px;
      list-style: none;
      padding: 0px;
      justify-self: left;
      margin-bottom: 37px;
    }
    .left-img {
      width: 60px;
    }
    .mobile-view-eventname {
      font-size: $mobileview-centeralign-eventname;
      width: 150px;
      text-align: center;
      font-weight: bold;
      white-space: nowrap;
    }
    .mobile-view-eventdate {
      font-size: 14px;
      width: 150px;
      text-align: center;
      height: 34px;
    }
    .row {
      display: flex;
      flex-wrap: nowrap;
      justify-self: center;
    }
  
    .secondcol {
      margin-top: 20px;
    }

    .container {
      width: 100%;
      margin: 0px;
      align-items: center;
      .horizontal {
        width: 80%;
        height: 3px;
        background-color: $cx-white
      }
    }
  
    .computerview-timeline {
      color: $cx-green;
      position: relative;
      .ul {
        list-style: none;
      }
    }
  
    .computerview-timeline li {
      list-style: none;
      float: left;
      width: 25%;
      position: relative;
      text-align: center;
      .status {
        font-size: 18px;
        font-weight: bold;
        padding-top: 15px;
      }
      .eventdate {
        padding: 10px 10px;
        font-size: 16px;
      }
    }
    ul:nth-child(1) {
      color: #ffffff;
    }
  
    .computerview-timeline li:before {
      content: "";
      width: 21px;
      height: 22px;
      border: 4px solid #ffffff;
      border-radius: 50%;
      display: block;
      text-align: center;
      line-height: 50px;
      margin: 0 auto 10px auto;
      background: $cx-green;
      transition: all ease-in-out 0.3s;
      z-index: 999;
    }
  
    .computerview-timeline li:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 8px;
      background-color: rgb(255, 255, 255);
      top: 8%;
      left: -50%;
      z-index: -1;
      transition: all ease-in-out 0.3s;
    }
  
    .computerview-timeline li:first-child:after {
      content: none;
    }
  
    .computerview-timeline-2 {
      position: relative;
    }
  
    .computerview-timeline-2 li {
      list-style: none;
      float: left;
      width: 25%;
      position: relative;
      text-align: center;
      text-transform: uppercase;
    }
  }
  