.key-dates-timeline {
  position: relative;
  padding: 101px 50px;
  @include breakpoints(smallAndMedium) {
    background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
    padding: 4.875rem 2.5rem 2.5rem;
  }
  @include breakpoints(small) {
    padding: 4.875rem 2.5rem 2.5rem;
  }
  font-family: "GT Walsheim", "CathaySans_Rg";
  .timeline-section-row-title {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1280px;
    justify-content: space-evenly;
    width: fit-content;
    .timeline-title {
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      // letter-spacing: 4px;
      line-height: 2.5rem;
      color: $cx-grey-dark;
      max-width: 100%;
      position: relative;
      margin: 0 0 4rem;
      @include heading-big-font-size;

      @include breakpoints(smallAndMedium) {
        margin-bottom: 1.5rem;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .time-title {
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;
        width: 100%;
        margin-bottom: 0px;

        &.active {
          opacity: 1;
          transform: translateY(0);
          line-height: 2.5rem;
        }
        @include breakpoints(smallAndMedium) {
          flex: 0 0 100%;
          max-width: 100%;
          line-height: 18px;
        }
      }
    }
    .section-content-smallview {
      padding: 0px;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      // flex: 0 0 50%;
      max-width: 80%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-light;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;
      font-family: "GT Walsheim", "CathaySans_Rg";

      @include breakpoints(mediumUp) {
        display: none;
      }

      .keyEvent {
        // flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 0 0px 32px;

        .time-rounded-circle {
          margin-bottom: 16px;
        }
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }

      html,
      body {
        font-family: "GT Walsheim", "CathaySans_Rg";
      }
    }
  }
  .timeline-section-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1280px;
    justify-content: space-evenly;
    flex-direction: column;
    // align-items: center;

    .section-content-smallview {
      padding: 0px;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      // flex: 0 0 50%;
      max-width: 80%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-light;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;

      @include breakpoints(mediumUp) {
        display: none;
      }
      // width: 720px;

      .keyEvent {
        // flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 0 0px 32px;

        .time-rounded-circle {
          margin-bottom: 16px;
        }
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }

      html,
      body {
        font-family: "GT Walsheim", "CathaySans_Rg";
      }
    }

    .timeline-content {
      padding: 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      // flex: 0 0 50%;
      max-width: 100%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-light;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;
      font-family: "GT Walsheim", "CathaySans_Rg";
      @include breakpoints(smallOnly) {
        display: none;
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }
    }
  }

  .visual-cue {
    background-color: $cx-white;
    color: $cx-blue-link;
    position: absolute;

    right: calc(50% - 1.25rem);
    top: -1.25rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    @include extra-small-font-size;

    @include breakpoints(smallAndMedium) {
      top: -2.25rem;
    }

    .icon-arrow-down {
      animation: MoveUpDown 3s linear infinite;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  $mobileview-centeralign-eventname: 18px;
  .firstcol {
    margin-top: $mobileview-centeralign-eventname;
  }

  ul.mobileview-timeline:before {
    content: " ";
    background: $cx-green;
    display: inline-block;
    position: absolute;
    left: 16px;
    width: 4px;
    height: 87%;
    z-index: 400;
    margin-top: $mobileview-centeralign-eventname;
  }

  ul.mobileview-timeline > li {
    //margin: 35px 0;
    padding-left: 0px;
    list-style: none;
    text-align: center;
    margin-top: 35px;
  }
  ul.mobileview-timeline > li:before {
    content: " ";
    background: $cx-green;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 4px solid #fff;
    left: 8px;
    width: 20px;
    height: 20px;
    z-index: 400;
    margin-top: 16px;
  }
  ul.mobileview-timeline-last > li {
    //margin: 35px 0;
    padding-left: 0px;
    list-style: none;
    text-align: center;
    margin-top: 35px;
  }
  ul.mobileview-timeline-last > li:before {
    content: " ";
    background: $cx-green;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 4px solid #fff;
    left: 12%;
    width: 20px;
    height: 20px;
    z-index: 400;
  }
  ul.mobileview-timeline-2 {
    padding: 0px;
  }
  ul.mobileview-timeline-2 > li {
    margin-top: 35px;
    list-style: none;
    padding: 8px;
    justify-self: left;
    margin-bottom: 37px;
  }
  .left-img {
    width: 60px;
  }
  .mobile-view-eventname {
    font-size: $mobileview-centeralign-eventname;
    width: 150px;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
  }
  .mobile-view-eventdate {
    font-size: 14px;
    width: 150px;
    text-align: center;
    height: 34px;
  }
  .row {
    display: flex;
    flex-wrap: nowrap;
    justify-self: center;
  }

  .firstcol {
  }

  .secondcol {
    margin-top: 20px;
    position: relative;
  }
  .container {
    width: 100%;
    margin: 0px;
    padding-top: 20px;
    align-items: center;
  }

  .computerview-timeline {
    position: relative;
    .ul {
      list-style: none;
    }
  }

  .computerview-timeline li {
    list-style: none;
    float: left;
    width: 25%;
    position: relative;
    text-align: center;
    .status {
      font-size: 18px;
      font-weight: 400;
      padding-top: 15px;
      font-family: 'CathaySans_Rg';
      font-style: normal;
      line-height: 22px;
      color: $cx-dark-green;
    }
    .eventdate {
      padding: 10px 10px;
      font-size: 16px;
      font-family: 'CathaySans_Rg';
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: $cx-dark-green;
    }
  }
  ul:nth-child(1) {
    color: $cx-grey-dark;
  }

  .computerview-timeline li:before {
    content: "";
    width: 21px;
    height: 22px;
    border: 4px solid #ffffff;
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 50px;
    margin: 0 auto 10px auto;
    background: #006564;
    transition: all ease-in-out 0.3s;
    z-index: 999;
  }

  .computerview-timeline li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 8px;
    background-color: $cx-dark-green;
    top: 8%;
    left: -50%;
    z-index: -1;
    transition: all ease-in-out 0.3s;
  }

  .computerview-timeline li:first-child:after {
    content: none;
  }

  .computerview-timeline-2 {
    position: relative;
  }

  .computerview-timeline-2 li {
    list-style: none;
    float: left;
    width: 25%;
    position: relative;
    text-align: center;
    text-transform: uppercase;
  }
}
