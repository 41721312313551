.header-section {
  //background-color: $cx-grey-platinum;
  position: relative;
  // padding: 2.4375rem 4.875rem;
  padding: 2rem calc((100vw - 960px) / 2);
  background-color: $cx-white;
  @include breakpoints(smallAndMedium) {
    padding: 30px 15px;
  }

  .basic-section-row {
    // display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1280px;
    justify-content: center;

    .section-title {
      // margin-bottom: 24px;
      // display: flex;
      justify-content: center;
      text-align: left;
      align-items: center;
      // letter-spacing: 4px;
      line-height: 2.5rem;
      color: $cx-grey-dark;
      max-width: 70%;
      position: relative;
      font-family: "GT Walsheim", "CathaySans_Rg";
      // margin: 0 0 4rem;
      @include heading-big-font-size;

      @include breakpoints(smallAndMedium) {
        // margin-bottom: 1.5rem;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .title {
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;

        &.active {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    .section-content {
      //   padding: 0 32px 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      //   flex: 0 0 50%;
      max-width: 100%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-regular;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      // display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      font-size: 1rem;

      // @include breakpoints(smallOnly) {
      //   display: none;
      // }

      .stats-container {
        display: flex;
        width: 100%;

        .container {
          position: relative;
          text-align: center;
          display: flex;
          min-height: 125px;

          .container-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .container-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: 0 1vw;

            h1 {
              color: $cx-grey-dark;
              margin: 0px;
              @include cx-font-regular;
            }
          }
        }
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      a {
        color: #367878;
        font-weight: bold;
      }
    }
  }
}
