.journey {
  background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
  color: $cathay-dark-grey;
  padding-top: 40px;
  padding-bottom: 40px;

  &__heading {
    color:#006564;
    font-size:18px;
    line-height:28px;
    font-family: "CathaySans_Rg";
  }


  @include breakpoints(smallAndMedium) {
    padding-left: 16px;
    padding-right: 16px;
  }

  & > .container {
    max-width: 1280px;
    padding-left: 0px;
    padding-right: 0px;
  }

  &__card {
    height: min-content;

    &__img {
      width: 100%;
      object-fit: cover;
    }

    @include breakpoints(smallOnly) {
      &--right {
        transform: translateY(0) !important;
      }
    }
  }

  &__card-textbox {
    position: relative;
    top: -40px;
    padding: 40px;
    background-color: $cathay-gold-light-grey;
    max-width: 400px;
    margin-bottom: -20px;

    &--right {
      margin-left: auto;
    }

    @include breakpoints(smallAndMedium) {
      padding: 24px;
      max-width: 304px;
    }
    
    &__title{
      line-height: 40px;
    }

    &__detail {
      font-size: 16px;
      line-height: 20.48px;
    }

    &__info-box {
      display: flex;
      flex-direction: column;
      gap: 11px;

      &__row {
        display: flex;
        flex-direction: row;
        gap: 11px;
      }
    }
  }
}