.brand-hub-statement-section {
  position: relative;
  padding: 0rem 4.875rem;
  background-color: $cx-grey-silver;

  @include breakpoints(smallAndMedium) {
    padding: -1rem 2.5rem 2.5rem;
  }

  .statement-section-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1280px;
    justify-content: center;

    .section-content {
      line-height: 1.25rem;
      color: $cx-grey-dark;
      flex: 0 0 100%;
      max-width: 100%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;;
      @include cx-font-light;

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }
    }
  }

  .visual-cue {
    background-color: $cx-white;
    color: $cx-blue-link;
    position: absolute;
    cursor: pointer;
    right: calc(50% - 1.25rem);
    top: -1.25rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 2;
    @include extra-small-font-size;

    @include breakpoints(smallAndMedium) {
      top: -2.25rem;
    }

    .icon-arrow-down {
      background-color: white;
      animation: MoveUpDown 3s linear infinite;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
  }

}
