.event-details {
  //background-color: $cx-grey-platinum;
  position: relative;

  .basic-section-row {
    background-image: linear-gradient($cx-grey-gradient, $cx-white);
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    // max-width: 1280px;
    flex-direction: row;
    justify-content: center;
    align-content: center;

    .description {
      font-size: 1rem;
      display: flex;
    }

    .section-title {
      // margin-bottom: 24px;
      // display: flex;
      // text-align: left;
      // align-items: center;
      // letter-spacing: 4px;
      // line-height: 2.5rem;
      // color: $cx-green;
      // width: 100%;
      // position: relative;
      // font-family: "CathaySans_Rg";
      // margin: 0 0 2rem;
      // font-size: 24px;
      color: $cx-grey-dark;
      line-height: 2.375rem;
      // letter-spacing: 4px;
      margin-bottom: 1.125rem;
      text-align: left;
      font-family: "GT Walsheim";

      @include breakpoints(smallAndMedium) {
        margin-bottom: 1.5rem;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .title {
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;

        &.active {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .title::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -2rem;
        transform: translate(-50%, 0);
        width: 3.75rem;
        height: 0.125rem;
        background: $cx-green;
        @include breakpoints(smallAndMedium) {
          bottom: -1rem;
        }
      }
    }

    .section-content {
      //   padding: 0 32px 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      //   flex: 0 0 50%;
      max-width: 100%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-regular;
      align-content: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: row;
      width: 100%;

      .stats-container {
        display: flex;
        width: 100%;

        .container {
          position: relative;
          text-align: center;
          display: flex;
          min-height: 125px;

          .container-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .container-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: 0 1vw;

            h1 {
              color: $cx-green;
              margin: 0px;
              @include cx-font-regular;
            }
          }
        }
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      a {
        color: $cx-blue-link;
      }

      .content-left {
        display: flex;
        justify-content: center;
        width: 50%;
        @include breakpoints(smallAndMedium) {
          width: 100%;
        }
      }

      .content-container {
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-content: center;
        padding: 10%;
        @include breakpoints(smallAndMedium) {
          padding: 0;
        }
      }

      .content-right {
        display: flex;
        justify-content: right;
        width: 50%;
        @include breakpoints(smallAndMedium) {
          width: 100%;
          justify-content: center;
        }
      }

      .row {
        display: flex;
      }

      .empty-row {
        display: flex;
        height: 1rem;
      }
      .img-left {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .img-right {
        display: flex;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .white-bg {
    background-color: $cx-white;
    background-image: none;
  }

  .container-fluid {
    padding: 4.875rem calc((100vw - 960px) / 2);
    @include breakpoints(smallAndMedium) {
      padding: 4.875rem 2.5rem 2.5rem;
    }
  }

  .non-first-part {
    @include breakpoints(largeUp) {
      padding-top: 0;
    }
  }

  .desktop-hide {
    display: none;
    @include breakpoints(smallAndMedium) {
      display: block;
    }
  }
  .mobile-hide {
    @include breakpoints(smallAndMedium) {
      display: none;
    }
  }
}
