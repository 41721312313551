$brushwing-width: 2.125rem;
$brushwing-height: 2.5rem;

.brushwing {
	position: absolute;
	transform: translateX(-25%);
	left: 29%;
	top: .1rem;
	width: $brushwing-width;
	height: $brushwing-height;

	@include breakpoints(largeUp) {
		left: 26%;
		top: 2.3rem;
	}

	.brush_ar {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
	}

	.brush_a {
		opacity: 0;
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;

		@include breakpoints(smallAndMedium) {
			width: 70%;
		}
	}

	.brush_b {
		opacity: 0;
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;

		@include breakpoints(smallAndMedium) {
			width: 70%;
		}
	}

	&.show {
		.brush_a {
			@extend .animation-brush_a
		}

		.brush_b {
			@extend .animation-brush_b
		}
	}
}

.animation-brush_a {
	animation-name: brush_a;
	animation-duration: 2s;
	animation-delay: .5s;
	animation-iteration-count: 1;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}

.animation-brush_b {
	animation-name: brush_b;
	animation-duration: 2s;
	animation-delay: .5s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
}

@keyframes brush_a {
	0% {
		opacity: 0;
		transform: translate(-14.706%, 38.75%) rotate(320deg) scale(0.1, 0.1);
	}
	20% {
		opacity: 0;
		transform: translate(-14.706%, 38.75%) rotate(320deg) scale(0.1, 0.1);
	}
	27.5% {
		opacity: 1;
		transform: translate(4.412%, 27.5%) rotate(330deg) scale(0.25, 0.25);
	}
	35% {
		transform: translate(5.147%, 20%) rotate(340deg) scale(0.50, 0.50);
	}
	42.5% {
		transform: translate(7.353%, 10%) rotate(350deg) scale(0.75, 0.75);
	}
	50% {
		opacity: 1;
	  	transform: translate(0%, 0%) rotate(360deg) scale(1, 1);
	}
	100% {
		opacity: 1;
		transform: translate(0%, 0%) rotate(360deg) scale(1, 1);
	}
}

@keyframes brush_b {
	0% {
		opacity: 0;
		transform: translate(-147.059%, 87.5%) scale(0.01, 0.01);
	}
	20% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	  	transform: translate(0%, 0%) scale(1, 1);
	}
	100% {
		opacity: 1;
	  	transform: translate(0%, 0%) scale(1, 1);
	}
}
