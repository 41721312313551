.video-section {
  background-color: $cx-white;
  position: relative;
  padding-bottom: 80px;
  @include breakpoints(smallAndMedium) {
    padding: 0px 16px 16px 10px;
  }

  .basic-section-row {
    // display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1280px;
    justify-content: center;
    // align-items: center;

    .section-title {
      margin-bottom: 39.82px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      // letter-spacing: 4px;
      line-height: 2.5rem;
      color: $cx-grey-dark;
      // max-width: 70%;
      position: relative;
      font-family: 'GT Walsheim', 'CathaySans_Rg';
      // margin: 0 0 4rem;
      @include heading-big-font-size;

      @include breakpoints(smallAndMedium) {
        margin-bottom: 1.5rem;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .title {
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;

        &.active {
          opacity: 1;
          transform: translateY(0);
        }
        @include breakpoints(smallAndMedium) {
          font-size: 24px;
        }
      }

      .dash-line {
        background-color: #c1b49a;
        flex: 1 1 auto;
        margin: 0;
        opacity: 0.5;
        height: 1px;
        width: 0;
        position: absolute;
        bottom: 2rem;
        transition-property: width;
        transition-duration: 1.25s;
        transition-delay: 1s;
        transition-timing-function: cubic-bezier(0.28, 0.11, 0.32, 1);

        &.active {
          width: 5.625rem;
        }

        @include breakpoints(smallAndMedium) {
          bottom: 0;
        }
      }
    }

    .section-content-smallview {
      padding: 0 32px 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      // flex: 0 0 50%;
      max-width: 80%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-regular;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;

      @include breakpoints(mediumUp) {
        display: none;
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }
    }

    .section-content {
      padding: 0 32px 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      // flex: 0 0 50%;
      max-width: 100%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-regular;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;
      @include breakpoints(smallAndMedium) {
        padding: 0;
      }

      .videoBox {
        // justify-content: center;
        // align-items: center;
        // width: 100%;
        // height: 584.274px;
        // flex-shrink: 0;
        position: relative;
        overflow: hidden;
        width: 100%;
      }

      .videoBox iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }
    }
  }
}
