.footer {
  padding: 16px;
  display: flex;
  background-color: $cathay-dark-green;
  color: $cathay-white;

  &__container {
    width: 1280px;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &__first-row {
    display: flex;
    margin: auto;
    margin-bottom: 32px;
    align-items: center;
    justify-content: space-between;
    align-items: center;

    @include breakpoints(smallAndMedium) {
      display: block;
    }

  }

  &__text {
    margin-bottom: 0px;
  }

  &__link-items {
    width: fit-content;
    font-family: GT Walsheim;
    font-size: 18px;
    text-align: left;
    display:inline-block;
    border-right: 1px solid #ccc;
    padding-right: 25px;
    padding-left: 25px;

    & > .link{
      color: $cathay-white;
      display: flex;
      gap: 8px;
      text-decoration: none;
      cursor: pointer;
    }

    &:hover {
      text-decoration: underline !important;
    }

    @include breakpoints(smallAndMedium) {
      display: block;
      padding-left: 0px;
      border-right: 0px;
      margin-bottom: 24px;
    }
  }

  &__link-items:first-of-type {
    padding-left: 0px;
  }

  &__link-items:last-of-type {
    border-right: 0px;
  }

  &__lang {
    align-self: flex-start;
    
    &__icon {
      width: 24px;
      height: 24px;
      color: $cx-white;
      margin-bottom: 1px;
    }

    button {
      background-color: transparent;
      border: transparent;
      color: $cx-white;

      :hover {
          text-decoration: underline;
      }
    }
  }

  &__lang button p{
    margin-bottom: 0px;
  }

  &__lang button:first-of-type p {
    border-right: 1px solid #ccc;
    padding-right: 10px;
  }

  &__bottom {

    &__copyright {
      font-family: 'GT Walsheim';
      font-size: 14px;
      margin-top: 8px;
  
      a:link{
        color: $cathay-white;
      }
  
      &__link {
        color: $cathay-white;
        text-decoration: none;
  
        &:hover {
          color: $cathay-white;
          text-decoration: underline;
        }
      }
    }
  }

  &__link {
    color: $cathay-white;
    text-decoration: underline;

    &:hover {
      color: $cathay-white;
    }
  }

  &__divider {
    display: none;
    
    @include breakpoints(smallAndMedium) {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $cathay-white;
      margin-top: 32px;
      margin-bottom: 32px;
      opacity: 0.75;

    }
  }
}