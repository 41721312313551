@font-face {
  font-family: "cx-icons";
  src: url("assets/fonts/cx-icons.eot");
  src: url("assets/fonts/cx-icons.eot?#iefix-wepioz") format("embedded-opentype"), url("assets/fonts/cx-icons.svg") format("svg"),
    url("assets/fonts/cx-icons.woff") format("woff"), url("assets/fonts/cx-icons.ttf") format("truetype");

  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: "cx-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-depart:before {
  content: "\e602";
}

.icon-question:before {
  content: "\e609";
}

.icon-warning:before {
  content: "\e607";
}

.icon-tick:before {
  content: "\e608";
}

.icon-menu:before {
  content: "\e60c";
}

.icon-arrival:before {
  content: "\e603";
}

.icon-swish:before {
  content: "\e601";
}

.icon-arrow-down:before {
  content: "\e600";
}

.icon-arrow-up:before {
  content: "\e604";
}

.icon-arrow-forward:before {
  content: "\e605";
}

.icon-arrow-backward:before {
  content: "\e606";
  float: right;
}

.icon-magnify:before {
  content: "\e60a";
}

.icon-calendar:before {
  content: "\e60b";
}

.icon-plane:before {
  content: "\e60d";
}

.icon-plus:before {
  content: "\e60e";
}

.icon-bed:before {
  content: "\e60f";
}

.icon-marco-polo:before {
  content: "\e610";
}

.icon-close-circle:before {
  content: "\e611";
}

.icon-profile:before {
  content: "\e612";
}

.icon-instagram:before {
  content: "\e613";
}

.icon-twitter:before {
  content: "\e614";
}

.icon-facebook:before {
  content: "\e615";
}

.icon-pinterest:before {
  content: "\e616";
}

.icon-youtube:before {
  content: "\e617";
}

.icon-linkedin:before {
  content: "\e618";
}

.icon-weibo:before {
  content: "\e619";
}

.icon-renren:before {
  content: "\e61a";
}

.icon-youku:before {
  content: "\e61b";
}

.icon-alert:before {
  content: "\e61c";
}

.icon-flight-contrary:before {
  content: "\e61d";
}

.icon-settings:before {
  content: "\e61e";
}

.icon-home-app:before {
  content: "\e61f";
}

.icon-flight-status:before {
  content: "\e620";
}

.icon-continue:before {
  content: "\e621";
}

.icon-tick-round:before {
  content: "\e622";
}

.icon-down:before {
  content: "\e623";
}

.icon-up:before {
  content: "\e624";
}

.icon-expand:before {
  content: "\e625";
}

.icon-collapse:before {
  content: "\e626";
}

.icon-location:before {
  content: "\e627";
}

.icon-notifly:before {
  content: "\e628";
}

.icon-star-rating:before {
  content: "\e629";
}

.icon-calendar:before {
  content: "\e62a";
}

.icon-card-flip:before {
  content: "\e62b";
}

.icon-share:before {
  content: "\e62c";
}

.icon-expand-contrary:before {
  content: "\e62d";
}

.icon-close-contrary:before {
  content: "\e62e";
}

.icon-alert-contrary:before {
  content: "\e62f";
}

.icon-flight:before {
  content: "\e630";
}

.icon-settings-contrary:before {
  content: "\e631";
}

.icon-flight-status-contrary:before {
  content: "\e632";
}

.icon-continue-contrary:before {
  content: "\e633";
}

.icon-tick-contrary:before {
  content: "\e634";
}

.icon-home-contrary:before {
  content: "\e635";
}

.icon-profile-contrary:before {
  content: "\e636";
}

.icon-information:before {
  content: "\e637";
}

.icon-travel-alert:before {
  content: "\e638";
}

.icon-guides-circle:before {
  content: "\e639";
}

.icon-guides-contrary:before {
  content: "\e63a";
}

.icon-travel-alert-contrary:before {
  content: "\e63b";
}

.icon-globe:before {
  content: "\e63c";
}

.icon-tooltip-contrary:before {
  content: "\e63d";
}

.icon-loading:before {
  content: "\e63e";
}

.icon-guides:before {
  content: "\e63e";
}

.icon-arrow-left-smallspace:before {
  content: "\e640";
}

.icon-arrow-right-smallspace:before {
  content: "\e641";
}

.icon-dragonair:before {
  content: "\e919";
}

.icon-flight-hotel:before {
  content: "\e643";
}

.icon-flight-hotel-contrary:before {
  content: "\e644";
}

.icon-hotel:before {
  content: "\e645";
}

.icon-hotel-contrary:before {
  content: "\e646";
}

.icon-best-value-guarantee:before {
  content: "\e647";
}

.icon-flight-hotel-horizontal:before {
  content: "\e648";
}

.icon-flight-hotel-horizontal-contrary:before {
  content: "\e649";
}

.icon-close:before {
  content: "\e64a";
}

.icon-location:before {
  content: "\e64b";
}

.icon-google-plusone-share-contrary:before {
  content: "\e64c";
}

.icon-twitter-contrary:before {
  content: "\e64d";
}

.icon-facebook-contrary:before {
  content: "\e64e";
}

.icon-pinterest-contrary:before {
  content: "\e64f";
}

.icon-youtube-contrary:before {
  content: "\e650";
}

.icon-linkedin-contrary:before {
  content: "\e651";
}

.icon-print-contrary:before {
  content: "\e652";
}

.icon-email-contrary:before {
  content: "\e653";
}

.icon-douban-contrary:before {
  content: "\e654";
}

.icon-sinaweibo-contrary:before {
  content: "\e655";
}

.icon-airport:before {
  content: "\e656";
}

.icon-airport-round:before {
  content: "\e657";
}

.icon-airport-contrary:before {
  content: "\e658";
}

.icon-highlight:before {
  content: "\e659";
}

.icon-highlight-round:before {
  content: "\e65a";
}

.icon-highlight-contrary:before {
  content: "\e65b";
}

.icon-number-1-round:before {
  content: "\e65c";
}

.icon-number-2-round:before {
  content: "\e65d";
}

.icon-number-3-round:before {
  content: "\e65e";
}

.icon-sign-in:before {
  content: "\e65f";
}

.icon-sign-out:before {
  content: "\e660";
}

.icon-number-4-round:before {
  content: "\e661";
}

.icon-number-5-round:before {
  content: "\e662";
}

.icon-number-6-round:before {
  content: "\e663";
}

.icon-number-7-round:before {
  content: "\e664";
}

.icon-number-8-round:before {
  content: "\e665";
}

.icon-number-9-round:before {
  content: "\e666";
}

.icon-phone-round:before {
  content: "\e667";
}

.icon-mail-round:before {
  content: "\e668";
}

.icon-down-round:before {
  content: "\e66b";
}

.icon-first-baggage:before {
  content: "\e66d";
}

.icon-separator:before {
  content: "\e66e";
}

.icon-manage-booking:before {
  content: "\e669";
}

.icon-manage-booking-round:before {
  content: "\e66a";
}

.icon-manage-booking-contrary:before {
  content: "\e66c";
}

.icon-food:before {
  content: "\e66f";
}

.icon-connection:before {
  content: "\e670";
}

.icon-round-expand:before {
  content: "\e671";
}

.icon-information-contrary:before {
  content: "\e672";
}

.icon-rotate-screen .path1:before {
  content: "\e673";
  color: #c5c0bf;
}
.icon-rotate-screen .path2:before {
  content: "\e674";
  color: #c5c0bf;
  margin-left: -0.9697265625em;
}
.icon-rotate-screen .path3:before {
  content: "\e675";
  color: #010101;
  margin-left: -1.05em;
}

.icon-pause:before {
  content: "\e676";
}
.icon-play:before {
  content: "\e677";
}
.icon-skip-back:before {
  content: "\e678";
}
.icon-skip-forward:before {
  content: "\e679";
}
.icon-trailer:before {
  content: "\e800";
}
.icon-train:before {
  content: "\e900";
}
.icon-wechat:before {
  content: "\e901";
}
.icon-back-to-top:before {
  content: "\e902";
}
.icon-external-link:before {
  content: "\e903";
}
.icon-bag:before {
  content: "\e904";
}
.icon-cellular-data:before {
  content: "\e905";
}
.icon-check-in:before {
  content: "\e906";
}
.icon-download:before {
  content: "\e907";
}
.icon-fax:before {
  content: "\e908";
}
.icon-credit-card:before {
  content: "\e909";
}
.icon-flight-plus:before {
  content: "\e90a";
}
.icon-lock:before {
  content: "\e90b";
}
.icon-no-cost:before {
  content: "\e90c";
}
.icon-online-exclusive:before {
  content: "\e90d";
}
.icon-price-promise:before {
  content: "\e90e";
}
.icon-medical-expenses:before {
  content: "\e90f";
}
.icon-personal-accident:before {
  content: "\e910";
}
.icon-personal-baggage:before {
  content: "\e911";
}
.icon-personal-liability:before {
  content: "\e912";
}
.icon-seat-selection:before {
  content: "\e913";
}
.icon-shortest-duration:before {
  content: "\e914";
}
.icon-special-assistant:before {
  content: "\e915";
}
.icon-trip-cancellation:before {
  content: "\e916";
}
.icon-wechat-contrary:before {
  content: "\e917";
}
.icon-whatsapp-contrary:before {
  content: "\e918";
}
.icon-line-contrary:before {
  content: "\e91a";
}
.icon-kakaotalk-contrary:before {
  content: "\e91b";
}
.icon-asia-miles:before {
  content: "\e92c";
}
.icon-laptop-and-mobile-phone:before {
  content: "\e92d";
}
.icon-notifly-contrary:before {
  content: "\e92e";
}
.icon-language:before {
  content: "\e92f";
  color: #0f748f;
}
.icon-notification-center:before {
  content: "\e930";
}
.icon-notification-center-thick:before {
  content: "\e931";
}
.icon-profile-alt:before {
  content: "\e932";
}
.icon-sign-in-alt:before {
  content: "\e933";
}
.icon-tumblr:before {
  content: "\e934";
}
.icon-site-search-bar-cancel:before {
  content: "\e935";
}
.icon-hide-pw:before {
  content: "\e936";
}
.icon-show-pw:before {
  content: "\e937";
}
.icon-asia-miles-no-circle:before {
  content: "\e703";
}
.icon-HidePW:before {
  content: "\e936";
  color: #0f748f;
}
.icon-ShowPW:before {
  content: "\e937";
  color: #0f748f;
}
.icon-flight-hotel .icon-expand {
  position: absolute;
  font-size: 0.2em;
  left: 2em;
  top: 0;
}
.icon-flight-hotel-contrary .icon-expand-contrary {
  position: absolute;
  font-size: 0.2em;
  left: 2em;
  top: 0;
}
.icon-flight-hotel-horizontal .icon-expand {
  position: absolute;
  font-size: 0.325em;
  left: 4.8em;
  top: -0.28em;
}
.icon-flight-hotel-horizontal-contrary .icon-expand-contrary {
  position: absolute;
  font-size: 0.325em;
  left: 4.8em;
  top: -0.28em;
}
