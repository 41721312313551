.subscribe-section {
  margin: 0 0 0;
  // padding: 5rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: $cx-green;
  background: linear-gradient(135deg, $cx-green 0%, $tooltip-default-border-color 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include breakpoints(largeUp) {
    margin: 0 0 0rem;
    height: 36rem;
    padding: 0 1rem;
  }

  @include breakpoints(mediumOnly) {
    margin: 0 0 0rem;
    height: 40rem;
    padding: 0rem 1rem;
  }

  @include breakpoints(smallOnly) {
    margin: 0 0 0rem;
    height: 52rem;
    padding: 1.5rem 2.5rem;
  }

  .img-vapour-trail-section {
    display: block;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    background-size: cover;
    font-family: 'object-fit: cover;';
  }

  .messages-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 960px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $cx-white;

    @include breakpoints(mediumOnly) {
      margin-top: 1.5rem;
      width: 600px;
    }

    @include breakpoints(smallOnly) {
      width: 280px;
    }

    @include breakpoints(smallAndMedium) {
      position: relative;
      // margin-top: -2.5rem;
      // margin-bottom: 2rem;
      top: 0;
      width: 100%;
      transform: translate(-50%, 0);
    }

    // .headline {
    //   max-width: 49.5rem;
    //   margin: 0 0 1.2rem;
    //   line-height: 2.5rem;
    //   color: $cx-white;
    //   text-align: center;
    //   text-shadow: 0 0 10px rgba(0, 0, 0, 1);
    //   opacity: 0;
    //   transform: translateY(1rem);
    //   transition: transform 1s ease-out, opacity 1s ease-out;
    //   font-family: 'GT Walsheim Light', 'Aktiv Grotesk W01 Bold1370651';
    //   @include heading-big-font-size;

    //   &.active {
    //     opacity: 1;
    //     transform: translateY(0);
    //   }

    //   @include breakpoints(smallOnly) {
    //     max-width: 18.75rem;
    //   }

    //   @include breakpoints(smallAndMedium) {
    //     color: $cx-grey-dark;
    //     text-shadow: none;
    //   }
    // }

    .container {
      width: 2000px;
      margin: 0;
      line-height: 2.5rem;
      color: $cx-white;
      text-align: center;
      // text-shadow: 0 0 10px rgba(0, 0, 0, 1);
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      font-family: 'CathaySans_Rg';

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      .subscribeTitle{
        // width:100%;
        @include breakpoints(smallAndMedium){
          line-height: 1.5rem;
        }
        font-size:16px;
        
        h1{
          font-size:30px;
        }
      }

      .subscribeForm{
        // margin: 1rem 0 0;
        .formSubscribe{
          // background-color: rgba(255,255,255,0.8);
          padding: 2rem;
          border-radius: 5px;
          width:100%;
          color: $cx-white;

          @include breakpoints(smallOnly) {
            padding: 0;
          }

          &.was-validated{
            .emailInput{
              &:invalid{
                border: 1px solid #d11f00;
              }
  
              &:valid{
                border-color: #0f748f;
                box-shadow: none;
              }
            }
          }

          .error{
            margin:0 0 1rem .25rem;
            color:#d11F00;
            vertical-align: top; 
            display: none;
            text-align: left;
            line-height: 1rem;

            i{
              font-size: 1.25rem
            }

            .error-description{
              margin-left: 0.5em;
              font-size: .7em;
              font-family: 'CathaySans_Bd';
              vertical-align: super;
            }
          }

          .emailInput{
            width:100%;
            border:1px solid #c6c2c1;
            line-height:1.5;
            padding:.75rem;
            -webkit-border-radius:0;
            -moz-border-radius:0;
            border-radius:0;
            -webkit-appearance:none;
            -moz-appearance:none;
            appearance:none;
            font-family: 'CathaySans_Lt';
            height:3.125rem;

            &:focus{
              outline:0;
              border:1px solid #0f748f;
              box-shadow: none;
            }

            &:active{
              outline:0;
              border:1px solid #0f748f;
              box-shadow: none;
            }

            // &:invalid{
            //   border: 1px solid #d11f00;
            // }

            // &:valid{
            //   border-color: #0f748f;
            //   box-shadow: none;
            // }
          }

          .invalidEmailInput{
            text-align: left;
            font-family: 'CathaySans_Bd';
            // font-size: 11.2px;
            font-size: .7em;
            height: 16px;
            line-height: 1rem;
          }

          .legal-notice{
            width: 100%;
            overflow: hidden;
            position: relative;
            margin: 1em 0 2em;
            padding-left:0 !important;

            .ui-checkbox{
              clip: rect(0 0 0 0);  
              position: absolute;
              display: none;

              &:checked+.legal-notice-label{
                .notice{
                  // color: green;
                  font-family: 'CathaySans_Bd';
                  text-align: left;
                }
                
                .icon-tick{
                  // top:0;
                  opacity: 1;
                  visibility: visible;
                  color: $cx-white;
                }
              }
            }

            .legal-notice-label{
              margin-left: 2.5em;
              display: block;
              position: relative;
              text-align: left;
              font-size: .7em;
              line-height: 1.5rem;


              .notice{
                // color: #4c4c4c;
                text-align: left;

                a{
                  color: $cx-white;
                }
              }

              .ui-checkbox-target{
                position: absolute;
                left: 0;
                top: 6px;
                // margin-left: 0;
                // width: 1.8em;
                // height: 1.8em;
                border: 1px solid #c6c2c1;
                // -webkit-border-radius: 2px;
                // -moz-border-radius: 2px;
                border-radius: 2px;
                display: block;
                float: left;
                height: 1.6em;
                width: 1.6em;
                margin-left: -2em;

                .icon-tick{
                  display: inline-block;
                  font-size: 1.325em;
                  // margin-top: 0;
                  opacity: 0;
                  // margin-top:-10px;
                  position: relative !important;
                  top: 0px !important;
                }
              }
            }

            .invalidCheckboxInput{
              text-align: left;
              font-family: 'CathaySans_Bd';
              // font-size: 11.2px;
              font-size: .7em;
              line-height: 1rem;
              // height: 16px;
            }
          }

          .cx-btn{
            width:200px;
            padding:.75rem;
            cursor:pointer;
            line-height:1.2;
            font-weight:300;
            border:1px solid transparent;
            -webkit-border-radius:2px;
            -moz-border-radius:2px;
            border-radius:2px;
            // background:linear-gradient(to top,#0f748f 0,#3391a2 100%);
            box-shadow:0 1px 0 0 #c6c2c1;
            background-color: #fff;
            color: #0f748f;

            &:hover{
              // background:linear-gradient(to top,#3291a2 0,#0d6b7c 100%);
              text-decoration:underline
            }

            &:active{
              // background:linear-gradient(to top,#3291a2 0,#0d6b7c 100%);
              text-decoration:underline
            }
            @include breakpoints(smallOnly) {
              // max-width: 100px;
            }
          }
        }
      }

      @include breakpoints(smallOnly) {
        max-width: 100%;
      }

      @include breakpoints(smallAndMedium) {
        color: $cx-grey-dark;
        text-shadow: none;
      }
    }
  }
}
