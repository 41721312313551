.history-highlights {
    background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
    color: $cathay-dark-grey;
    padding-top: 60px;
    padding-bottom: 88px;  
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'CathaySans_Rg';

    @include breakpoints(smallAndMedium) {
        padding-top: 48px;
        padding-left: 16px;
        padding-right: 16px;
    }

    & > .container {
        max-width: 1280px;
        padding-left: 0px;
        padding-right: 0px;
      }

    .history-highlight-container {
        display: flex;
        @include breakpoints(smallAndMedium){
            flex-direction: column;
            gap: 20px;
        }

        & > .highlight_container {
            flex: 4;
            margin-right: 30px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            @include breakpoints(smallAndMedium) {
                margin-right: 0px;
            }

            & > .name{
                color: #006564;
                font-size: 18px;
            }

            & > .title{
                font-family: 'GT Walsheim';
                font-size: 28px;
            }

            & > .description {
                margin-top: 15px;
            }

            & > .link {
                color: $cathay-dark-cyan;
                display: flex;
                gap: 10px;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }

        & > .video_container {
            flex: 6;
            position: relative;

            img, video {
                object-fit: cover;
                z-index: 5;
                max-height:422px;
                width: 100%;
            }

            & > .overlay_icon {
                width: 70px;
                height: 70px;
                position: absolute;
                z-index: 10;

                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .prize_container{
        margin-top: 40px;
        @include breakpoints(smallAndMedium) {
            padding-left: 12px;
            padding-right: 0;
        }

        & > .prize_cards_container{
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            gap: 24px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
            --ms-overflow-style: none;
            scrollbar-width: none;
        }
    }
    
    .history-card {
        flex: 0 0 auto;
        width: 410px;
        overflow: hidden;
    
        @include breakpoints(smallAndMedium) {
          width: 290px;
        }

        &__section_title {
            color:#006564;
            font-size:18px;
            line-height:28px;
            font-family: "CathaySans_Rg";
        }

        &__title {
            font-size: 24px;
            font-family: 'GT Walsheim';
        }
    
        &__image {
          width: 100%;
          height: 216px;
          object-fit: cover;
    
          @include breakpoints(smallAndMedium) {
            height: 170px;
          }
        }
    
        &__textbox {
          padding: 24px;
          background-color: $cathay-gold-light-grey;
          height: 100%;
    
          &__text {
            margin-bottom: 0;
          }
        }
    
      }
}