.brand-hub-basic-section {
  position: relative;
  padding: 4.875rem 4.875rem;
  background-image: linear-gradient($cx-grey-gradient, $cx-white);
  @include breakpoints(smallAndMedium) {
    padding: 4.875rem 2.5rem 2.5rem;
  }
  z-index: 1;

  .basic-section-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1280px;
    justify-content: center;
    // align-items: center;

    .section-title {
      // letter-spacing: 4px;
      line-height: 2.5rem;
      color: $cx-grey-dark;
      flex: 0 0 33%;
      max-width: 33%;
      position: relative;
      font-family: "GT Walsheim", "CathaySans_Rg";
      @include heading-big-font-size;

      @include breakpoints(smallAndMedium) {
        margin-bottom: 1.5rem;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .title {
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;

        &.active {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .dash-line {
        background-color: #c1b49a;
        flex: 1 1 auto;
        margin: 0;
        opacity: 0.5;
        height: 1px;
        width: 0;
        position: absolute;
        bottom: 2rem;
        transition-property: width;
        transition-duration: 1.25s;
        transition-delay: 1s;
        transition-timing-function: cubic-bezier(0.28, 0.11, 0.32, 1);

        &.active {
          width: 5.625rem;
        }

        @include breakpoints(smallAndMedium) {
          bottom: 0;
        }
      }
    }

    .section-content {
      line-height: 1.25rem;
      color: $cx-grey-dark;
      flex: 0 0 50%;
      max-width: 50%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-light;
      font-family: "CathaySans_Rg";

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }
      p {
        margin-bottom: 0px;
      }
    }
  }

  .visual-cue {
    background-color: $cx-white;
    color: $cx-blue-link;
    position: absolute;
    cursor: pointer;
    right: calc(50% - 1.25rem);
    top: -1.25rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    @include extra-small-font-size;

    @include breakpoints(smallAndMedium) {
      top: -2.25rem;
    }

    .icon-arrow-down {
      animation: MoveUpDown 3s linear infinite;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}
