@font-face {
  font-family: "CathaySans_Rg";
  src: url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Rg.eot?);
  src: url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Rg.eot?#iefix) format("embedded-opentype"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Rg.woff2) format("woff2"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Rg.woff) format("woff"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Rg.ttf) format("truetype"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Rg.svg#CathaySans_WCorp_Rg) format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "CathaySans_Lt";
  src: url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Lt.eot?);
  src: url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Lt.eot?#iefix) format("embedded-opentype"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Lt.woff2) format("woff2"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Lt.woff) format("woff"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Lt.ttf) format("truetype"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Lt.svg#CathaySans_WCorp_Lt) format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "CathaySans_Md";
  src: url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Md.eot?);
  src: url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Md.eot?#iefix) format("embedded-opentype"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Md.woff2) format("woff2"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Md.woff) format("woff"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Md.ttf) format("truetype"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Md.svg#CathaySans_WCorp_Md) format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "CathaySans_Bd";
  src: url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Bd.eot);
  src: url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Bd.eot?#iefix) format("embedded-opentype"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Bd.woff2) format("woff2"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Bd.woff) format("woff"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Bd.ttf) format("truetype"),
    url(https://assets.cathaypacific.com/fonts/en/CathayPacific/CathaySans_WCorp_Bd.svg#CathaySans_WCorp_Bd) format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Aktiv Grotesk W01 Light1370681";
  src: url("assets/fonts/AktivGroteskCorp-Light.eot"), url("assets/fonts/AktivGroteskCorp-Light.ttf"), url("assets/fonts/AktivGroteskCorp-Light.woff"),
    url("assets/fonts/AktivGroteskCorp-Light.svg");
}

@font-face {
  font-family: "Aktiv Grotesk W01 Regular";
  src: url("assets/fonts/AktivGroteskCorp-Regular.eot"), url("assets/fonts/AktivGroteskCorp-Regular.ttf"),
    url("assets/fonts/AktivGroteskCorp-Regular.woff"), url("assets/fonts/AktivGroteskCorp-Regular.svg");
}

@font-face {
  font-family: "Aktiv Grotesk W01 Medium";
  src: url("assets/fonts/AktivGroteskCorp-Medium.eot"), url("assets/fonts/AktivGroteskCorp-Medium.ttf"),
    url("assets/fonts/AktivGroteskCorp-Medium.woff"), url("assets/fonts/AktivGroteskCorp-Medium.svg");
}

@font-face {
  font-family: "Aktiv Grotesk W01 Bold1370651";
  src: url("assets/fonts/AktivGroteskCorp-Bold.eot"), url("assets/fonts/AktivGroteskCorp-Bold.ttf"), url("assets/fonts/AktivGroteskCorp-Bold.woff"),
    url("assets/fonts/AktivGroteskCorp-Bold.svg");
}

@font-face {
  font-family: "GT Walsheim Light";
  src: url("assets/fonts/GT-Walsheim-Light.eot"), url("assets/fonts/GT-Walsheim-Light.ttf"), url("assets/fonts/GT-Walsheim-Light.woff"),
    url("assets/fonts/GT-Walsheim-Light.woff2");
}

@font-face {
  font-family: "GT Walsheim";
  src: url("assets/fonts/GT-Walsheim-Regular.eot"), url("assets/fonts/GT-Walsheim-Regular.ttf"), url("assets/fonts/GT-Walsheim-Regular.woff"),
    url("assets/fonts/GT-Walsheim-Regular.woff2");
}

@font-face {
  font-family: "GT Walsheim";
  src: url("assets/fonts/GT-Walsheim-Bold.eot"), url("assets/fonts/GT-Walsheim-Bold.ttf"), url("assets/fonts/GT-Walsheim-Bold.woff"),
    url("assets/fonts/GT-Walsheim-Bold.woff2");
  font-weight: 700;
}

@mixin cx-font-light {
  font-weight: 300;
  font-family: "CathaySans_Lt", sans-serif;
}

@mixin cx-font-regular {
  font-weight: 300;
  font-family: "CathaySans_Rg", sans-serif;
}

@mixin cx-font-medium {
  font-weight: 300;
  font-family: "CathaySans_Md", sans-serif;
}

@mixin cx-font-bold {
  font-weight: 300;
  font-family: "CathaySans_Bd", sans-serif;
}

// Keep all font sizes here
// Currently keeping both EM and REM, but REM should be used while EM needs to be faded out
@mixin heading-biggest-font-size {
  font-size: 2.5em; //40px
}

@mixin heading-bigger-font-size-rem {
  @include rem-fallback(font-size, 2.375); //38px
}

@mixin heading-bigger-font-size {
  font-size: 2.375em; //38px
}

@mixin heading-big-font-size {
  font-size: 2em; //32px
}

@mixin heading-medium-font-size-rem {
  @include rem-fallback(font-size, 1.75); //28px
}

@mixin heading-medium-font-size {
  font-size: 1.75em; //28px
}

@mixin heading-standard-font-size-larger {
  font-size: 1.5625em; //25px
}

@mixin heading-standard-font-size-rem {
  @include rem-fallback(font-size, 1.5); //24px
}

@mixin heading-standard-font-size {
  font-size: 1.5em; //24px
}

@mixin heading-small-font-size-rem {
  @include rem-fallback(font-size, 1.375); //22px
}

@mixin heading-small-font-size {
  font-size: 1.375em; //22px
}

@mixin larger-font-size-rem {
  @include rem-fallback(font-size, 1.25); //20px
}

@mixin larger-font-size {
  font-size: 1.25em; //20px
}

@mixin large-font-size {
  font-size: 1.125em; //18px
}

@mixin base-font-size {
  font-size: 1em; //16px
}

@mixin medium-font-size {
  font-size: 0.9375em; //15px
}

@mixin small-font-size-rem {
  @include rem-fallback(font-size, 0.875); //14px
}

@mixin small-font-size {
  font-size: 0.875em; //14px
}

@mixin smaller-font-size-rem {
  @include rem-fallback(font-size, 0.8125); //13px
}

@mixin smaller-font-size {
  font-size: 0.8125em; //13px
}

@mixin smallest-font-size-rem {
  @include rem-fallback(font-size, 0.75); //12px
}

@mixin smallest-font-size {
  font-size: 0.75em; //12px
}

@mixin tiny-font-size-rem {
  @include rem-fallback(font-size, 0.6875); //11px
}

@mixin tiny-font-size {
  font-size: 0.6875em; //11px
}

@mixin extra-tiny-font-size {
  font-size: 0.65em; //10.4px
}

@mixin extra-small-font-size-rem {
  @include rem-fallback(font-size, 0.625); //10px
}

@mixin extra-small-font-size {
  font-size: 0.625em; //10px
}
