.sidebar {
  @include breakpoints(largeUp) {
    display: none;
  }

  @include breakpoints(largeDown) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    min-height: 100dvh;
    background-color: $cx-white;
    
    &__header {
      position: relative;

      &__image {
        width: 100%;
        height: 188px;
        object-fit: cover;
      }

      &__close {
        position: absolute;
        top: 24px;
        right: 24px;
        opacity: 0.85; // Color similar to $cathay-dark-grey
        outline: none;
        border: none;
        box-shadow: none;
      }

      &__faq-button {
        position: absolute;
        bottom: 100vh;
        color: $cathay-dark-cyan;
        padding: 16px;
        font-size: 16px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          color: $cathay-dark-cyan;
          text-decoration: underline;
        }
      }

      &__recap-button {
        position: absolute;
        bottom: 0;
        color: $cathay-dark-cyan;
        padding: 16px;
        font-size: 16px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          color: $cathay-dark-cyan;
          text-decoration: underline;
        }
      }

      &__portal-button {
        position: absolute;
        bottom: 40px;
        color: $cathay-dark-cyan;
        padding: 16px;
        font-size: 16px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          color: $cathay-dark-cyan;
          text-decoration: underline;
        }
      }

      &__faq-icon {
        margin-left: 8px;
      }

      &__recap-icon {
        margin-left: 2px;
        padding-right: 10px;
      }

      &__portal-icon {
        margin-left: 7px;
        padding-right: 15px;
      }


      &__apply-now-button {
        position: absolute;
        background-color: transparent;
        height: 30px;
        width: 122px;
        border-radius: 15px;
        font-size: 13px;
        font-family: "GT Walsheim", sans-serif;
        
        &:hover {
          text-decoration: underline;
        }

      }
    }

    &__content {
      padding: 16px;
    }

   
  
    &__nav-link {
      margin-top: 24px;
      font-size: 18px;
      font-family: "GT Walsheim", sans-serif;
      position: relative;
      display: block;
      color: $cx-dark-grey;
      text-decoration: none;
      
      &--current {
        font-weight: 900;
      }

      &:hover {
        text-decoration: underline;
        color: $cx-dark-grey; // need this to override css default blue
      }

      &::after {
        content: "";
        position: absolute;
        top: 3px;
        right: 4px;
        width: 12px;
        height: 12px;
        border-top: 2px solid $cx-dark-grey;
        border-right: 2px solid $cx-dark-grey;
        transform: rotate(45deg);
      }
    }
  }
}

.navbar-wrapper {
  z-index: 3;
  padding: 0;
  margin: 0;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  -webkit-font-smoothing: subpixel-antialiased;

  &.sticky {
    position: fixed;
    transform: translateY(-100%);
    // top: -104px;
  }

  &.sticky-transition {
    -webkit-transition: transform 0.4s ease;
    -moz-transition: transform 0.4s ease;
    -ms-transition: transform 0.4s ease;
    -o-transition: transform 0.4s ease;
    transition: transform 0.4s ease;
  }

  &.sticky-show {
    // position: fixed;
    transform: translateY(0);
    // top: 0;
    -webkit-transition: transform 0.4s ease;
    -moz-transition: transform 0.4s ease;
    -ms-transition: transform 0.4s ease;
    -o-transition: transform 0.4s ease;
    transition: transform 0.4s ease;
  }
}

.navbar-top-ribbon {
  @include breakpoints(largeUp) {
    height: 32px;
    width: auto;
    border-left: 0;
    border-bottom: 0;
    background-color: $cx-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "GT Walsheim";
    padding-left: 9px;
    padding-right: 9px;

    &__container {
      position:inherit;
      right: 6px;
      height: 100%;
      width: 1280px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__left-group {
      display: none;
    }
    
    &__homelink {
      display: none;
    } 

    
    &__out-left-group{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: auto;
    }

    &__right-group {
      display: flex;
      justify-self: self-end;
      align-items: center;
      height: 100%;
      width: auto;
    }

    &__language {
      &__icon {
        width: 20px;
        height: 20px;
        margin-right: 4px;
        color: $cx-green;
        margin-top: 2px;
      }
    }

    &__recap {
      &__icon {
        width: 16px;
        height: 16px;
        // margin-right: 4px;
        color: #006564;
        // margin-top: -2px;
      }
    }

    &__faq__icon{
      color:#006564;
    }

    &__portal__icon{
      color:#006564;
      width: 16px;
      height: 16px;
    }

    &__separator {
      color: #D9D9D9;
      padding-left: 8px;
    }

    &__button {
      border: none;
      color: #006564;
      padding-left: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      height: 25px;
      width: auto;
      transition: all 0.3s;
      gap:5px;

      &:hover {
        color: $cx-green;
        text-decoration: underline;
      }
    }
  }

  @include breakpoints(smallAndMedium) {
    font-family: "CathaySans_Rg";
    height: 2.875rem;
    background-color: $cx-yellow;
    position: relative;
    z-index: 3;
    width: 100%;
    padding: 0 !important;

    &__container {
      width: 100%;
      padding: 0 15px;
      margin: auto;
      height: 100%;
      vertical-align: middle;
    } 
    
    &__left-group {
      height: 100%;
      float: left;
      display: table;
      margin-left: -15px;
    } 

    &__burger-menu-button {
      color: $cx-grey-dark;
      display: table-cell;
      position: relative;
      overflow: hidden;
      vertical-align: middle;
      text-decoration: none;
      padding: 0 15px 0 15px;
      cursor: pointer;
    }


    &__homelink {
      display: block;
      position: absolute;
      left: 50%;
      height: 100%;
      text-decoration: none;

      &__icon-wrapper {
        position: absolute;
        left: -1.5rem;
        width: 3rem;
        display: table;
        height: 100%;
        text-align: center;
        padding: 9px 0;
      }

      &__icon {
        vertical-align: middle;
        margin: auto;
      }
    }

    &__out-left-group {
      display: none;
    }

    &__right-group {
      display: none;
    }
  }
}

.navbar-primary {
  @include breakpoints(largeUp) {
    background-color: $cx-white;
    width: auto;
    vertical-align: middle;
    border-left: 0;
    border-bottom: 0;
    padding: 0;
    padding-left: 12px;
    padding-right: 12px;
    font-family: "GT Walsheim";

    &__container {
      width: 1280px;
      height: 74px;
      //vertical-align: middle;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__left-group {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      gap: 24px;
      align-items: center;
      float: left;
      height: 35px;
    }

    &__homelink {
      padding-right: 12px;
      align-self: flex-start;

      &__icon {
        height: 25px;
        cursor: pointer;
      }
    }
        
    &__nav-link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: none;
      color: $cx-grey-dark;
      text-align: center;
      font-size: 1rem;
      height: 74px;
      padding-top: 25px;
      width: auto;
      transition: all 0.3s;
      overflow-y: hidden;

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 3px;
        background: $cx-green;
        transition: width 0.25s;
        align-self: flex-end;
      }

      &:hover::after {
        width: 100%;
        z-index: 1000;
      }
    }

    &__right-group {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      float: right;
      height: 100%;
      align-items: center;
    }
    
    &__right-group &__nav-link {
      border: 1px solid black;
      background-color: white;
      border-radius: 15px;
      color: $cx-grey-dark;
      text-decoration: none;
      height: 1.875rem;
      line-height: 1.1875rem;
      padding: .3125rem .9375rem;
      width: auto;
      font-size: 1rem;
      font-weight: 400;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      transition: all 0.3s;
      
      &:hover {
        text-decoration: underline;
      }

      &:active {
        text-decoration: underline;
      }
    }
  }
  
  @include breakpoints(smallAndMedium) {
    display: none;
  }
}
