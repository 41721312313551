.highlights-section {
  background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
  color: $cathay-dark-grey;
  padding-top: 40px;
  padding-bottom: 80px;
  padding-left: 12px;
  padding-right: 12px;

  @include breakpoints(smallAndMedium) {
    padding-top: 48px;
    padding-left: 16px;
    padding-right: 0;
    padding-bottom: 40px;
  }

  & > .container {
    max-width: 1280px;
    padding-left: 0px;
    padding-right: 0px;
  }

  &__name {
    margin-bottom: 20px;
    font-size: 18px;
    height: 13px;
    color:#006564;
  }

  &__title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 28px;
    color: #2d2d2d;
  }

  &__description {
    font-size: 16px;
    color: #2d2d2d;
  }

  &__cards-container.container {
    @include breakpoints(smallAndMedium) {
      padding-left: 12px;
      padding-right: 0;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 24px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    --ms-overflow-style: none;
    scrollbar-width: none;
  }

  /* Highlights Card */
  .highlights-card {
    flex: 0 0 auto;
    width: 300px;
    overflow: hidden;

    @include breakpoints(smallAndMedium) {
      width: 254px;
    }

    &__img {
      width: 100%;
      height: 216px;
      object-fit: cover;

      @include breakpoints(smallAndMedium) {
        height: 170px;
      }
    }

    &__textbox {
      padding: 24px;
      background-color: $cathay-gold-light-grey;
      height: 100%;

      &__text {
        margin-bottom: 0;
      }
    }

  }
}
