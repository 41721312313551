@mixin breakpoints($type) {

  @if $type == smallUp {
    @content;
  } @else if $type == smallOnly {
    @media only screen and (max-width: #{$screen-sm-max}) { @content; }
  } @else if $type == mediumUp {
    @media only screen and (min-width: #{$screen-md-min}) { @content; }
  } @else if $type == mediumOnly {
    @media only screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) { @content; }
  } @else if $type == smallAndMedium {
    @media only screen and (max-width: #{$screen-md-max}) { @content; } 
  } @else if $type == largeDown {
    @media only screen and (max-width: #{$screen-lg-min}) { @content; }
  } @else if $type == largeUp {
    @media only screen and (min-width: #{$screen-lg-min}) { @content; }
  } @else if $type == mediumlargeUp {
    @media only screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-md-lg-min}) { @content; }
  } @else if $type == extraLargeUp {
    @media only screen and (min-width: #{$screen-extra-lg-min}) { @content; }
  }
}
