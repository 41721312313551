.history-banner {
    padding-left: 12px;
    padding-right: 12px;

    &__container {
        display: flex;
        max-width: 1280px;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 0px;
        padding-right: 0px;
    }

    &__img {
        width: 66%;
        
        @include breakpoints(smallAndMedium) {
            width: 100%;
            // this is so the edges of the image are trimmed and
            // we preserve some height to the img, just like in the figma
            min-height: 400px;
            object-fit: cover;
        }
    }

    &__heading {
        color:#006564;
        font-size:18px;
        line-height:28px;
        font-family: "CathaySans_Rg";
    }

    &__card {
        background: $cathay-gold-light-grey;
        padding: 40px;
        // transform: translateX(-40px);
        margin-left: -40px;
        max-width: calc(33% + 40px);

        @include breakpoints(smallAndMedium) {
            // transform: translateY(-40px) translateX(0);
            margin-left: 0;
            margin-top: -40px;
            max-width: 400px;
        }
    }
}