.vapour-trail {
  z-index: 1;
  position: absolute;
  top: 10rem;
  left: 0;
  width: 0;
  height: 0;
  opacity: 1;
  border-style: solid;
  border-width: 0 0 0 0;
  border-color: $cx-grey-platinum rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
  transform: skewY(-18.5deg);
  transform-origin: right;

  &.show {
    @include breakpoints(largeUp) {
      animation-name: vapour_large;
      animation-duration: .8s;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }

    @include breakpoints(mediumOnly) {
      animation-name: vapour_medium;
      animation-duration: .8s;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }

    @include breakpoints(smallOnly) {
      animation-name: vapour_small;
      animation-duration: .8s;
      animation-iteration-count: 1;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes vapour_large {
  0% {
    border-width: 0 0 0 0;
    top: 13rem;
  }
  100% {
    border-width: 1rem 24.5vw 0 0;
    top: 5.2rem;
  }
}

@keyframes vapour_medium {
  0% {
    border-width: 0 0 0 0;
    top: 7rem;
  }
  100% {
    border-width: .5rem 25vw 0 0;
    top: 2.3rem;
  }
}

@keyframes vapour_small {
  0% {
    border-width: 0 0 0 0;
    top: 5rem;
  }
  100% {
    border-width: .5rem 23vw 0 0;
    top: 2.2rem;
  }
}
