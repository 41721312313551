.faq-section {
  position: relative;
  // padding: 0 calc((100vw - 1280px) / 2);
  padding-left: 12px;
  padding-right: 12px;
  background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
  color: $cathay-dark-grey;
  
  @include breakpoints(smallAndMedium) {
    padding: 0 15px;
  }

  .card {
    background: transparent;
    border: none;
  }

  .empty-space{
    height: 6rem;
    width: 100%;
    background: transparent;
  }

  .card-common{
    background: transparent;
    border: none;
    font-weight: 400;
    display: flex;
    text-align: left;
    align-items: center;
    line-height: 2rem;
    width: 100%;
    position: relative;
    font-size: 16px;
  }

  .card-header{
    @extend .card-common;
    font-family: "CathaySans_Rg";
    color: #367878;
    ;
  }

  .card-body{
    @extend .card-common;
    margin-top: 24px;
    margin-bottom: 12px;
    color: #2D2D2D;
  }

  .question-row {
    width: 100%;
    cursor: pointer;

    .answer {
      opacity: 0;
      transform: translateY(-1rem);
      transition: transform 1s ease-out, opacity 1s ease-out, max-height 1s ease-out;
      cursor: default;
      max-height: 0;
      overflow: hidden;

      &.show {
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-in, opacity 1s ease-in;
        max-height: 1000px;
      }
    }
  }

  .flip {
    transform: rotate(180deg);
  }

  .faq {
    width: 100%;
  }

  .top-divider {
    padding: 0;
  }

  .hidden-divider {
    margin: 0px;
  }

  .col-1 {
    max-width: 20px;
    margin: 1px 0px;
  }

  .basic-section-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1280px;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-bottom:40px;

    .description {
      font-size: 1rem;
      display: flex;
    }

    .section-initial {
      font-weight: 400;
      margin-top: 40px !important;
      display: flex;
      text-align: left;
      align-items: center;
      line-height: 2rem;
      color: #006564;
      width: 100%;
      position: relative;
      font-family: "CathaySans_Rg";
      margin: 0 0 0rem;
      font-size: 16px;
    }

    .section-title {
      font-weight: 400;
      margin-bottom: 24px !important;
      display: flex;
      text-align: left;
      align-items: center;
      line-height: 2rem;
      color: $cx-dark-grey;
      width: 100%;
      position: relative;
      font-family: "GT Walsheim";
      margin: 0 0 0rem;
      font-size: 28px;

      @include breakpoints(smallAndMedium) {
        margin-bottom: 1.5rem;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .title {
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;

        &.active {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .title::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -2rem;
        transform: translate(-50%, 0);
        width: 3.75rem;
        height: 0.125rem;
        background: $cx-green;
        @include breakpoints(smallAndMedium) {
          bottom: -1rem;
        }
      }
    }

    .section-content {
      //   padding: 0 32px 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      //   flex: 0 0 50%;
      max-width: 100%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-regular;
      align-content: center;
      width: 100%;
      .stats-container {
        display: flex;
        width: 100%;

        .container {
          position: relative;
          text-align: center;
          display: flex;
          min-height: 125px;

          .container-img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          .container-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: 0 1vw;

            h1 {
              color: $cx-green;
              margin: 0px;
              @include cx-font-regular;
            }
          }
        }
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
        margin-bottom:40px;
      }

      a {
        color: $cx-blue-link;
      }

      .content-left {
        display: flex;
        width: 50%;
        justify-content: center;
      }

      .content-left-container {
        display: flex;
        max-width: 380px;
        flex-direction: column;
        align-self: center;
        justify-content: center;
      }

      .content-right {
        display: flex;
        width: 50%;
      }

      .row {
        display: flex;
      }

      .empty-row {
        display: flex;
        height: 1rem;
      }
      .img-left {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 300px;
      }
      .img-right {
        display: flex;
        width: 100%;
        object-fit: cover;
        max-height: 143px;
      }
    }
  }

  .section-content-smallview {
    // padding: 0 32px 0;
    line-height: 1.25rem;
    color: $cx-grey-dark;
    max-width: 100%;
    opacity: 0;
    transform: translateY(1rem);
    transition: transform 1s ease-out, opacity 1s ease-out;
    @include cx-font-regular;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    text-align: center;

    @include breakpoints(mediumUp) {
      display: none;
    }
    // width: 720px;

    .stats-container {
      width: 100%;

      .container {
        position: relative;
        text-align: center;
        display: flex;
        min-height: 125px;
        width: 100%;

        .container-img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .container-content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          h1 {
            color: $cx-green;
            margin: 0px;
            @include cx-font-regular;
          }
        }
      }
    }

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    @include breakpoints(smallAndMedium) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    a {
      color: $cx-blue-link;
    }
  }

  .apply-now-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__apply-button {
      margin-top: 25px;
      display: inline-block;
      text-align: center;
      appearance: none;
      border: 0;
      outline: 0;
      cursor: pointer;
      border-radius: 0;
      padding: 18px 24px;
      min-width: 235px;
      margin-bottom: 8px;
      transition: all 0.25s ease-in-out;
      font-size: 16px;
      font-family: 'CathaySans_Rg';
  
      color: $cathay-white;
      background-color: $cathay-dark-green;
  
      &:hover {
        color: $cathay-white;
        background-color: $cathay-dark-green-darker;
        text-decoration: underline;
      }
    }
  }

}
