.eday-highlight-section {
  background-image: linear-gradient($cx-grey-gradient, $cx-white);
  padding: 4.875rem 4.875rem;
  position: relative;
  vertical-align: middle;
  font-family: "GT Walsheim", "CathaySans_Rg";
  @include breakpoints(largeUp) {
    &.with-collage {
      min-height: 40rem;
      //padding: 4.875rem 4.875rem;

      .section-content {
        //top: 7rem !important;
        padding: 4.875rem 4.875rem;

        &.right {
          right: -4.375rem !important;
        }

        &.left {
          left: -4.375rem !important;
        }
      }

      .section-collage {
        left: 4.875rem !important;
      }
    }

    &.without-collage {
      // min-height: 30rem;
      // margin-top: 6.25rem;
      // height: 40rem !important;
    }
  }

  @include breakpoints(smallAndMedium) {
    padding: 0;
    vertical-align: middle;
  }
  .eday-content {
    transform: translateY(-3rem);
    transition: transform 1s ease-out, opacity 1s ease-out;
    &.active {
      opacity: 1;
      transform: translateY(0rem);
    }
  }
  .carousel-section-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    position: relative;
    margin: 0 auto;
    max-width: 1280px;
    // justify-content: flex-end;
    align-items: center;

    @include breakpoints(smallAndMedium) {
      // padding: 0 0 4rem;
      flex-wrap: wrap;
    }
    .eday-content {
      transform: translateY(-3rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      &.active {
        opacity: 1;
        transform: translateY(0rem);
      }
    }
    .section-carousel {
      z-index: 1;
      width: 60%;
      display: none;
      display: block;
      opacity: 1;

      @include breakpoints(smallAndMedium) {
        width: 100vw;
        display: block;
      }

      @include breakpoints(largeUp) {
        min-width: 30rem;
      }

      .slick-slide-wrapper {
        position: relative;
      }

      .icon-arrow-backward {
        opacity: 0.9;
        color: $cx-blue-link;
        background-color: #f9f9f9;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.75rem 0.5rem 0.75rem 0.9375px;
        position: absolute;
        border-radius: 50%;
        top: calc(50% - 1.25rem);
        z-index: 1;
        cursor: pointer;
        left: calc(0% - 20px);
      }

      .icon-arrow-forward {
        opacity: 0.9;
        color: $cx-blue-link;
        background-color: #f9f9f9;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.75rem 0.9375rem 0.75rem 0.5rem;
        position: absolute;
        top: calc(50% - 1.25rem);
        //top: calc(50%-1.25rem);
        // bottom: .3125rem;
        z-index: 1;
        cursor: pointer;
        border-radius: 50%;
        right: calc(0% - 20px);
      }

      .btn-carousel-video-play {
        background-color: rgba(255, 255, 255, 0);
        border-color: rgba(255, 255, 255, 0);
        display: block;
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        cursor: pointer;
        height: 3.5rem;
        width: 3.5rem;
        padding: 0;
        z-index: 1;

        @include breakpoints(smallAndMedium) {
          height: 5rem;
          width: 5rem;
          bottom: 50%;
          right: 50%;
          transform: translate(50%, 50%);
        }

        .icon-play {
          color: $cx-white;
          display: block;
          font-size: 2rem;

          @include breakpoints(smallAndMedium) {
            font-size: 3.5rem;
          }

          &:before {
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
          }
        }
      }

      &.right {
        @include breakpoints(largeUp) {
          position: relative;
          right: 0;
        }
      }

      &.left {
        @include breakpoints(largeUp) {
          position: relative;
          left: 0;

          .icon-arrow-backward {
            left: 0;
            right: unset;
          }

          .icon-arrow-forward {
            left: unset;
            right: 0;
          }
        }
      }

      .slick-slider {
        @include breakpoints(smallAndMedium) {
          width: 100vw;
        }
        .slick-slide {
          .slide-img,
          .slide-video {
            width: 100%;
          }
          .slide-video {
            // cursor: pointer;
          }
        }
      }
    }

    .section-content {
      width: 40%;
      padding: 2.1875rem 2.5rem;
      background-color: rgba(255, 255, 255, 0);
      z-index: 2;
      position: relative;

      @include breakpoints(largeUp) {
        min-width: 22rem;
      }

      .btn-read-more {
        opacity: 0;
        padding: 1px 1rem 0;
        height: 3rem;
        min-width: 9.5rem;
        border: 1px solid $cx-blue-link;
        border-radius: 2px;
        color: $cx-blue-link;
        background-color: rgba(255, 255, 255, 0);
        cursor: pointer;
        transform: translateY(1rem);
        transition: all 1s ease-out 0.3s;
        @include small-font-size;
        @include cx-font-regular;

        &.active {
          opacity: 1;
          transform: translateY(0rem);
        }

        &:hover,
        &:active {
          text-decoration: underline;
        }
      }

      &.right {
        background-color: rgba(255, 255, 255, 0);

        @include breakpoints(largeUp) {
          position: relative;
          top: 0;
          right: 0;
        }
      }

      &.left {
        background-color: rgba(255, 255, 255, 0);

        @include breakpoints(largeUp) {
          position: relative;
          top: 0;
          left: 0;
        }

        @include breakpoints(mediumUp) {
          height: 21rem;
        }
      }

      @include breakpoints(smallAndMedium) {
        padding: 4.875rem 2.5rem 2.5rem;
        width: 100%;
        position: relative;
        top: unset;
        right: unset;
        left: unset;
      }

      @include breakpoints(smallOnly) {
        padding: 4.875rem 2.5rem 2.5rem;
        display: flex;
        flex-direction: column;
      }

      .title {
        color: $cx-grey-dark;
        line-height: 2.375rem;
        // letter-spacing: 4px;
        margin-bottom: 1.125rem;
        text-align: left;
        @include breakpoints(smallOnly) {
          text-align: left;
        }
      }

      .description-wrapper {
        @include breakpoints(smallOnly) {
          min-height: 224px;
          // min-height: 108px;
        }
        .description-context {
          // transition: all 1s;
          color: $cx-grey-dark;
          margin-bottom: 0px;
          line-height: 1.25rem;
          @include cx-font-light;
          position: absolute;
          margin-right: 40px;

          a {
            color: $cx-blue-link;
          }

          &.fadeOut {
            opacity: 0;
            // width:0;
            // height:0;
            transition: opacity 1s;
          }

          &.fadeIn {
            opacity: 1;
            // width:100px;
            // height:100px;
            transition: opacity 1s;
          }

          .currentNo {
            font-size: 24px;
          }

          .totalNo {
            font-size: 16px;
          }
        }
        .description {
          // transition: all 1s;
          color: $cx-grey-dark;
          margin-bottom: 1.125rem;
          line-height: 1.25rem;
          @include cx-font-light;
          position: absolute;
          margin-right: 40px;

          a {
            color: $cx-blue-link;
          }

          &.fadeOut {
            opacity: 0;
            // width:0;
            // height:0;
            transition: opacity 1s;
          }

          &.fadeIn {
            opacity: 1;
            // width:100px;
            // height:100px;
            transition: opacity 1s;
          }

          .currentNo {
            font-size: 24px;
          }

          .totalNo {
            font-size: 16px;
          }
        }
      }
    }

    .section-collage {
      display: none;
      flex-wrap: wrap;
      position: relative;
      width: 58%;
      top: 3rem;

      .collage-first-col {
        width: 58%;
        padding: 0.5rem;
      }

      .collage-second-col {
        width: 42%;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .collage-item-1 {
        width: 70%;
        margin-left: 30%;
        position: relative;
        top: -2rem;
        right: 0;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out;
      }

      .collage-item-2 {
        width: 100%;
        position: relative;
        top: -1rem;
        right: 0;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out 0.3s;
      }

      .collage-item-3 {
        width: 100%;
        position: relative;
        opacity: 0;
        transform: translateY(2rem);
        transition: all 1s ease-out 0.15s;
      }

      &.active {
        .collage-item-1,
        .collage-item-2,
        .collage-item-3 {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .collage-img,
      .collage-video {
        width: 100%;
      }

      &.show {
        @include breakpoints(largeUp) {
          display: flex;
          transition: transform 1s ease-out;
        }
      }

      &.right {
        right: 0;
      }

      &.left {
        left: 0;
      }

      .collage-video {
        cursor: pointer;
      }

      .btn-collage-video-play {
        background-color: rgba(255, 255, 255, 0);
        border-color: rgba(255, 255, 255, 0);
        display: block;
        position: absolute;
        top: calc(50% - 1.75rem);
        left: calc(50% - 1.75rem);
        cursor: pointer;
        height: 3.5rem;
        width: 3.5rem;
        padding: 0;
        z-index: 1;

        .icon-play {
          color: $cx-white;
          display: block;
          font-size: 2rem;

          &:before {
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
          }
        }

        @include breakpoints(largeUp) {
          top: initial;
          left: initial;
          right: 0.5rem;
          bottom: 0.5rem;
        }
      }
    }
  }

  .dash-line {
    background-color: #c1b49a;
    flex: 1 1 auto;
    margin: 0;
    opacity: 0.5;
    height: 0;
    width: 1px;
    position: absolute;
    top: -11rem;
    right: 23rem;
    transition-property: height;
    transition-duration: 1.25s;
    transition-delay: 0.3s;
    transition-timing-function: cubic-bezier(0.28, 0.11, 0.32, 1);

    &.active {
      height: 8.125rem;
    }

    @include breakpoints(smallAndMedium) {
      display: none;
    }
  }
}
