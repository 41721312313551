.about-hackathon-section {
    background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
    color: $cathay-dark-grey;
    padding-top: 60px;
    padding-bottom: 88px;  
    padding-left: 12px;
    padding-right: 12px;
    font-family: 'CathaySans_Rg';
  
    @include breakpoints(smallAndMedium) {
      padding-top: 48px;
      padding-left: 16px;
      padding-right: 16px;
    }
  
    & > .container {
      max-width: 1280px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .about-and-video-container{
        display: flex;
        @include breakpoints(smallAndMedium) {
            flex-direction: column;
        }

        & > .about_container {
            flex: 4;
            margin-right: 30px;

            display: flex;
            flex-direction: column;
            //align-items: center;
            justify-content: center;

            @include breakpoints(smallAndMedium) {
                margin-right: 0px
            }

            & > .name{
                color: #006564;
                font-size: 18px;
            }

            & > .title{
                font-family: 'GT Walsheim';
                font-size: 28px;
            }

            & > .description{
                margin-top: 15px;
            }
        }

        & > .video_container {
            flex: 6;
            position: relative;

            video {
                object-fit: cover;
                z-index: 5;
                width: 100%;
                max-height:422px;
            }

            & > .overlay_icon {
                width: 70px;
                height: 70px;
                position: absolute;
                z-index: 10;

                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .stats_container{
        margin-top: 10px;
        @include breakpoints(smallAndMedium) {
            margin-top: 30px
        }

        & > .title{
            font-size: 28px;
            font-family: 'GT Walsheim';
        }

        & > .stats_cards_container{
            display: flex;
            justify-content: space-between;
            gap: 25px;
            margin-top: 20px;
            @include breakpoints(smallAndMedium) {
              flex-direction: column;
            }
        }
    }

    &__stat_card {
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: 'CathaySans_Rg';
        width: 100%;
        height: 172px;
        background-color: $cathay-gold-light-grey;

        & > .number{
            color: #006564;
            font-size: 36px;
            margin-top: 45px;
        }

        & > .description{
            font-size: 16px;
            text-align: center;
        }
    }

    .learn_more_container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 30px;
        font-family: 'CathaySans_Rg';

        .learn_more_button{
            color: #367878;
            border: solid 1px #367878;
            line-height: 10px;
            max-width: 469px;
            height: 60px;
            padding: 10px 32px 10px 32px;
            text-align: center;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }
  }