.language-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    p:first-of-type {
        border-right: 1px solid #D9D9D9;
        padding-right: 10px;
    }

    p:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

@include breakpoints(mediumUp) {
    .wrapper {
        width: auto;
        background-color: $cathay-white;
    }

    .language-banner {
        align-items: center;
        height: 36px;
        width: 1280px;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: space-between;

        &__left-grp {
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            gap: 6px;
            // align-items: center;
            float: left;
            margin-top: 4px;

            &__top {
                display: flex;
                flex-direction: row;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 6px;
                    color: $cx-green;
                    margin-top: 15px;
                    margin-bottom: auto;
                    margin-left: auto;
                }
            }

            &__buttons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap:10px;
            }

            p {
                font-family: GT Walsheim;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.03px;
                text-align: left;
                margin-top: 15px;
                color: $cx-dark-grey;
            }
        }

        &__right-grp {
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            float: right;
            height: 100%;
            align-items: center;
            padding-right: 5px;

            &__close-button {
                width: 20px;
                height: 20px;
                cursor: pointer;
                padding: 4px;
                background-size: auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                float: right;
            }
        }

        @media (max-width: 1300px) { //to fix the left-padding of the language icon
            margin-left: 8px;
            margin-right: 8px;
            width: auto;
        }
    }
}

@include breakpoints(smallOnly) {
    .language-banner {
        width: auto;
        height: 65px;
        background-color: $cathay-white;

        &__left-grp {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__top {
                height: 20px;
                display: flex;
                align-items: center;
                position: relative;
                left: 10px;
                top: 10px;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    color: $cx-green;
                    margin-top: 3px;
                }
            }

            &__buttons {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                position: relative;
                left: 40px;
                justify-content: space-between;
                gap: 10px;
            }

            p {
                font-family: GT Walsheim;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.03px;
                text-align: left;
                margin-top: 15px;
                color: $cx-dark-grey;
            }
        }

        &__right-grp {
            position: relative;

            &__close-button {
                width: 3px;
                height: 3px;
                background-size: auto;
                padding-right: 40px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                position: absolute;
                right: 0;
                bottom: 40px;
            }
        }
    }
}