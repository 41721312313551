.event-detail {
  background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
  color: $cathay-dark-grey;
  background-color: #ffffff;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 12px;
  padding-right: 12px;
  @include breakpoints(smallAndMedium) {
    padding-top: 48px;
    padding-bottom: 40px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    gap: 50px;
    padding-left: 0px;
    padding-right: 0px;

    @include breakpoints(smallAndMedium) {
      gap: 20px;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    --bs-gutter-x: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }

  &__content-col {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__title {
    font-family: 'GT Walsheim';
    color: $cathay-dark-grey;
  }

  &__description {
    color: $cathay-dark-grey;
    margin-bottom: -1rem;
  }

  &__icon {
    height: 23px;
    width: 23px;
  }

  &__detail-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__detail {
    display: flex;
    gap: 10px;
  }

  &__apply-button {
    display: inline-block;
    text-align: center;
    appearance: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    border-radius: 0;
    padding: 18px 24px;
    min-width: 235px;
    margin-bottom: 8px;
    transition: all 0.25s ease-in-out;
    font-size: 16px;
    font-family: 'CathaySans_Rg';

    color: $cathay-white;
    background-color: $cathay-dark-green;

    &:hover {
      color: $cathay-white;
      background-color: $cathay-dark-green-darker;
      text-decoration: underline;
    }
  }

  @include breakpoints(smallAndMedium) {
    &__apply-button {
      width: 100%;
    }
  }

  &__img {
    object-fit: cover;
    width: 100%;
    max-height: 450px;
    display: block;

    @include breakpoints(smallAndMedium) {
      padding: 20px;
    }
  }

  @include breakpoints(smallAndMedium) {
    &__img-wrapper {
      padding: 0;
    }
  }

  &__cards {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    @include breakpoints(smallAndMedium) {
      flex-direction: column;
    }
  }
}

.event-card {
  display: flex;
  flex: 1 1 0;
  width: 0;
  align-items: flex-start;
  background: $cathay-gold-light-grey;
  padding: 20px;
  gap: 20px;

  &__img {
    padding: 20px;
    width: 35px;
    height: 35px;
  }

  &__textbox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.card_container {
  display: flex;
  flex: 1 1 0;
  background: $cathay-gold-light-grey;
  padding: 20px;
  gap: 20px;

  &__icon_col {
    flex: 1;
  }

  &__icon {
    width: 35px;
  }

  &__content_col{
    flex: 10;

    &__title{
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
}
