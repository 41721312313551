.statistics-section {
  background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
  color: $cathay-dark-grey;
  padding-top: 120px;
  padding-bottom: 88px;  
  padding-left: 12px;
  padding-right: 12px;

  @include breakpoints(smallAndMedium) {
    padding-top: 48px;
    padding-left: 0;
    padding-right: 0;
  }

  & > .container {
    max-width: 1280px;
    padding-left: 0px;
    padding-right: 0px;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    @include breakpoints(smallAndMedium) {
      flex-direction: column;
    }
  }

  &__images-col {
    flex-basis: 50%;
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    // Positioning the images
    &__img--top-left {
      position: relative;
      top: -48px;
    }

    &__img--bottom-left {
      position: relative;
      top: -48px;
      width: 85%;
      margin-left: auto;
    }

    &__img--bottom-right {
      position: relative;
      width: 85%;
      margin-right: auto;
      height: auto;
    }

    @include breakpoints(smallAndMedium) {
      flex-basis: 100%;
      width: 100%;
      display: flex;

      &__img {
        display: none;
      }

      &__img--bottom-right {
        display: block;
        width: 100%;
        position: static;
      }
    }
  }

  &__metrics-col {
    flex-basis: 50%;
    width: 50%;
    margin-bottom: 48px;

    @include breakpoints(smallAndMedium) {
      order: -1;
      flex-basis: 100%;
      width: 100%;
    }
  }

  &__title {
    text-align: center;
  }

  &__tagline {
    text-align: center;
    margin-bottom: 40px;
  }

  &__metrics {
    display: grid;
    grid-template-columns: repeat(6, 1fr); // Divide into 6 columns
    grid-gap: 40px;

    & div:nth-child(1) {
      grid-column: 2 / span 2; // Span 2 columns, starting from column 2
    }

    & div:nth-child(2) {
      grid-column: 4 / span 2; // Span 2 columns, starting from column 4
    }

    & div:nth-child(3), & div:nth-child(4), & div:nth-child(5) {
      grid-column: span 2; // Span 2 columns evenly for 3 items
    } 

    @include breakpoints(smallAndMedium) {
      grid-template-columns: repeat(1, 1fr);

      & > div:nth-child(n) {
        grid-column: span 1;
      }
    }
  }
}

/* Statistics Metric */
.statistics-metric {
  max-width: 186px;

  &__number {
    font-size: 36px;
    line-height: 40px;
    color: $cathay-dark-cyan;
    margin-bottom: 8px;
    text-align: center;
  }

  &__text {
    font-size: 16px;
    color: $cathay-dark-grey;
    text-align: center;
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
  }

  @include breakpoints(smallAndMedium) {
    max-width: 100%;
  }
}