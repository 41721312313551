.hackathon-role-section {
  background-color: $cx-white;
  color: $cathay-dark-grey;
  position: relative;
  padding-top: 50px;
  padding-bottom: 44px;
  @include breakpoints(smallAndMedium) {
    background: linear-gradient($cathay-pillar-flight 0%, $cathay-white 120px);
    padding: 48px 16px 34px;
  }

  .hackathonRole-container {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: space-between;
    max-width: 1020px;

    @include breakpoints(smallAndMedium) {
      flex-direction: column;
      max-width: 100%;
    }

    .hackathonRole {
      text-align: center;
      width: 300px;
      margin: auto;

      .rounded-circle-wrapper {
        padding: 0px;
        justify-content: center;
        align-content: center;
        .img {
          height: 39.38px;
          width: 43.75px;
        }

        .rounded-circle {
          margin-bottom: 40px;
          width: 80px;
          height: 80px;
          @include breakpoints(smallAndMedium) {
            margin-top: 40px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }

  .hackathonRole-paragraph {
    text-align: center;
    // margin-top: 40px;
    max-width: 1020px;
    margin-left: auto;
    margin-right: auto;

    @include cx-font-regular;
    @include breakpoints(smallAndMedium) {
      margin-top: 0px;
    }
  }

  .basic-section-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1280px;
    justify-content: center;
    // align-items: center;

    .section-title {
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      // letter-spacing: 4px;
      line-height: 2.5rem;
      color: $cx-grey-dark;
      max-width: 70%;
      position: relative;
      font-family: 'GT Walsheim', 'CathaySans_Rg';
      margin: 0 0 4rem;
      @include heading-big-font-size;

      @include breakpoints(smallAndMedium) {
        margin-bottom: 0px;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .title {
        opacity: 0;
        transform: translateY(-1rem);
        transition: transform 1s ease-out, opacity 1s ease-out;

        &.active {
          opacity: 1;
          transform: translateY(0);
        }
      }

      .dash-line {
        background-color: #c1b49a;
        flex: 1 1 auto;
        margin: 0;
        opacity: 0.5;
        height: 1px;
        width: 0;
        position: absolute;
        bottom: 2rem;
        transition-property: width;
        transition-duration: 1.25s;
        transition-delay: 1s;
        transition-timing-function: cubic-bezier(0.28, 0.11, 0.32, 1);

        &.active {
          width: 5.625rem;
        }

        @include breakpoints(smallAndMedium) {
          bottom: 0;
        }
      }
    }

    .section-content-smallview {
      padding: 0 32px 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      // flex: 0 0 50%;
      max-width: 80%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-regular;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;

      @include breakpoints(mediumUp) {
        display: none;
      }
      // width: 720px;

      .hackathonRole {
        // flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        padding: 0 16px 32px;

        .rounded-circle {
          margin-bottom: 16px;
        }
      }

      // .hackathonRole2{
      //   display: flex;
      //   justify-content: center;
      //   align-items: flex-start;
      //   text-align: center;
      //   width: 100%;
      //   margin: 8px 0;

      //   .rounded-circle-wrapper{
      //     padding: 0 8px;
      //     width: 100%;
      //     justify-content: center;
      //     align-content: center;

      //     .rounded-circle{
      //       margin-bottom: 16px;
      //       width: 80px;
      //       height: 80px;
      //     }
      //   }
      // }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }
    }

    .section-content {
      padding: 0 32px 0;
      line-height: 1.25rem;
      color: $cx-grey-dark;
      // flex: 0 0 50%;
      max-width: 80%;
      opacity: 0;
      transform: translateY(1rem);
      transition: transform 1s ease-out, opacity 1s ease-out;
      @include cx-font-regular;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;

      @include breakpoints(smallOnly) {
        display: none;
      }
      // width: 720px;

      // .hackathonRole{
      //   flex: 1;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   flex-direction: column;
      //   text-align: center;
      //   padding: 0 16px 0;

      //   .rounded-circle{
      //     margin-bottom: 16px;
      //   }
      // }

      .hackathonRole2 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        text-align: center;
        width: 100%;
        margin: 8px 0;

        .rounded-circle-wrapper {
          padding: 0 8px;
          justify-content: center;
          align-content: center;
          .img {
            height: 39.38px;
            width: 43.75px;
          }
          .rounded-circle {
            margin-bottom: 16px;
            width: 80px;
            height: 80px;
          }
        }
      }

      &.active {
        opacity: 1;
        transform: translateY(0);
      }

      @include breakpoints(smallAndMedium) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      a {
        color: $cx-blue-link;
      }
    }
  }

  .visual-cue {
    background-color: $cx-white;
    color: $cx-blue-link;
    position: absolute;
    cursor: pointer;
    right: calc(50% - 1.25rem);
    top: -1.25rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    @include extra-small-font-size;

    @include breakpoints(smallAndMedium) {
      top: -2.25rem;
    }

    .icon-arrow-down {
      animation: MoveUpDown 3s linear infinite;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}
